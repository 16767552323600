<template>
  <div>
    <section
      v-for="feature in featureddata"
      class="destinations subsection section"
    >
      <div class="site-section">
        <h2 class="site-section-title">
          {{ feature.featureContentTitle }}
        </h2>
      </div>

      <div class="featured-destinations__wrap">
        <div
          v-for="item in feature.destinations"
          data-type="navigation"
          data-name="feature-content-pod"
          class="featured-destinations__item"
        >
          <a
            v-if="item.destinationLinkUrl"
            :href="item.destinationLinkUrl"
            :aria-label="item.destinationLinkName"
            target="_self"
          >
            <div class="featured-destinations__card">
              <img
                v-if="item.destinationImageUrl"
                class="is-background featured-destinations__image"
                :alt="
                  item.destinationImageAltText
                    ? item.destinationImageAltText
                    : item.destinationLinkName
                "
                :src="item.destinationImageUrl"
              >

              <div class="featured-destinations__content">
                <div class="featured-destinations__content-overlay">
                  <div class="featured-destinations__content-center">
                    <span
                      v-if="feature.hoverText"
                      class="featured-destinations__content-smallText"
                    >
                      {{ feature.hoverText }}
                    </span>
                    <span class="featured-destinations__content-title">{{
                      item.destinationDescriptionText
                    }}</span>
                  </div>
                  <div
                    v-if="feature.hoverButton"
                    class="featured-destinations__content-bottom"
                  >
                    <button class="featured-destinations__content-btn">
                      <span class="btn-text">{{ feature.hoverButton }}</span>
                      <i class="arrow fa fa-arrow-right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ['featureddata']
};
</script>
