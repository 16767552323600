import Vue from 'vue';
import OverviewView from '../vue-components/tour-cms/overview.vue';

function overviewView() {
  new Vue({
    el: '#overview-section',
    components: {
      'overview-view': OverviewView
    },
    data() {
      return {
        overviewData: window.Cms.overviewModel,
        generatedModel: window.Cms.generatedModelSection,
        dictionaryModel: window.Cms.dictionaryValuesModel
      };
    }
  });
}
export default overviewView;
