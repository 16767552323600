<template>
  <div class="dropdown">
    <div class="dropdown-trigger">
      <button
        class="button"
        :class="{
          'filters-active': selectedRoute && selectedRoute !== 'all-routes',
          'no-dropdown': routes.length === 1
        }"
        :disabled="apierror"
      >
        <span v-if="!routes.length && !apierror"><i class="fas fa-lg fa-spin fa-circle-notch" /></span>
        <span v-if="routes.length > 1 && selectedRoute === 'all-routes'">Filter by Route</span>
        <span v-if="selectedRoute !== 'all-routes'">{{ selectedRoute }}</span>
        <span
          v-if="routes.length === 1"
          v-text="routes[0]"
        />
        <span
          v-if="routes.length > 1"
          class="icon is-small"
        >
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
          />
        </span>
      </button>
    </div>
    <ul
      v-if="routes.length > 1"
      class="dropdown-menu dropdown-content"
    >
      <li
        class="dropdown-item is-paddingless is-flex"
        :class="selectedRoute === 'all-routes' ? 'selected' : ''"
      >
        <label
          class="dropdown-label"
          for="all-routes"
        >
          <input
            id="all-routes"
            v-model="selectedRoute"
            type="radio"
            value="all-routes"
            @change="updateSelectedRoute(selectedRoute)"
          >
          All Routes
        </label>
      </li>
      <li
        v-for="route in routes"
        :key="route.index"
        class="dropdown-item is-paddingless is-flex"
        :class="route === selectedRoute ? 'selected' : ''"
      >
        <label
          class="dropdown-label"
          :for="route.index"
        >
          <input
            :id="route.index"
            v-model="selectedRoute"
            type="radio"
            :value="route"
            @change="updateSelectedRoute(selectedRoute)"
          >
          {{ route }}
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['model', 'routes', 'selectedroute', 'apierror'],
  data() {
    return {
      selectedRoute: 'all-routes'
    };
  },
  methods: {
    updateSelectedRoute() {
      this.$emit('update-selected-route', this.selectedRoute);
    }
  }
};
</script>
