<template>
  <section
    v-cloak
    v-if="modulesmodel.hasModules"
    class="brands-detail subsection section"
  >
    <div class="site-section-header">
      <h2
        v-if="modulesmodel.title && modulesmodel.modules?.length"
        class="site-section-title"
        v-text="modulesmodel.title"
      />
      <p
        v-if="modulesmodel.subtitle"
        class="site-section-subtitle"
        v-text="modulesmodel.subtitle"
      />
    </div>
    <div class="brands__content">
      <div
        v-for="(pod, index) in modulesmodel.modules"
        :key="index"
        data-type="product"
        :data-name="pod.linkUrl"
        class="pod-card"
      >
        <div class="pod-card-content">
          <div class="pod-card-header">
            <a
              :href="pod.linkUrl"
              class="pod-card-content__link"
              :alt="pod.Title"
              :aria-label="pod.Title"
              :title="pod.Title"
              :style="{
                backgroundImage: 'url(\'' + pod.imageUrl + '\')',
                backgroundPosition: '' + pod.leftFocalPoint + ' ' + pod.topFocalPoint + ''
              }"
            >
              <span class="hidden">Content module</span>
            </a>
            <div
              v-if="pod.brandName"
              class="pod-card-content__logo"
            >
              <img
                :src="pod.brandImageUrl"
                :alt="pod.brandName"
              >
            </div>
          </div>
          <div class="pod-card-content-body">
            <div class="pod-card-content-body__header">
              <a
                class="card-content-header-link"
                :href="pod.linkUrl"
              >
                <h3
                  class="pod-card-content-body-title"
                  v-text="pod.title"
                />
              </a>
              <h4
                v-if="pod.subTitle"
                class="pod-card-content-body-subtitle"
                v-text="pod.subTitle"
              />
              <p
                v-if="pod.showLoginCallToAction && !loginmodel.isLoggedIn"
                class="login-for-price-call-to-action"
              >
                <img
                  :src="
                    constants.env === 'local'
                      ? constants.LOCATION_STRING + loginmodel.logoUrl
                      : loginmodel.logoUrl
                  "
                  :alt="loginmodel.logoAltText"
                  class="logo-image"
                >
                <a
                  :href="loginmodel.loginUrl"
                  class="login-link"
                  @click="dataLayerLoginForPriceClicked()"
                  v-text="loginmodel.text"
                />
              </p>
            </div>
            <div
              class="pod-card-content-body-text"
              v-html="pod.text"
            />
          </div>
          <div class="pod-card-footer">
            <div class="pod-card-footer-inner">
              <a
                v-if="pod.linkName !== ''"
                class="pod-card-footer__link"
                :href="pod.linkUrl"
                :target="pod.target"
                :aria-label="pod.Title"
                v-text="pod.linkName"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import * as searchConstants from '../../../js/search-constants.js';
export default {
  props: ['modulesmodel', 'loginmodel'],
  data() {
    return {
      constants: searchConstants
    };
  },

  created() {},
  methods: {
    dataLayerLoginForPriceClicked() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'Login Clicked From Pod' });
    }
  }
};
</script>
