<template>
  <section class="search-brand-container">
    <div class="check-link-holder search-brand-wrapper">
      <a
        v-for="(brand, brandIndex) in filterTripsContent.vacationBrand.brands"
        :key="'brand' + brandIndex"
        class="check-link search-brand-item"
        :data-brand-name="brand.brandId"
        :value="brand.brandName"
        :class="{ 'is-active': isActive(brand) }"
        :aria-label="brand.brandName"
        href="#"
        @click.prevent="selectedBrands(brand)"
      >
        <div
          :class="{ 'is-square': brand.isSquare }"
          class="background-image-center contain logo-container"
          :style="{
            backgroundImage: 'url(\'' + constants.LOCATION_STRING + brand.brandLogoUrl + '\')'
          }"
        />
        <span class="icon icon-check is-small">
          <i
            class="fas fa-check"
            aria-hidden="true"
          />
        </span>
      </a>
    </div>
  </section>
</template>
<script>
import * as searchConstants from '../../search-constants.js';
import eventBus from '../../vue-functions/event-bus.js';

export default {
  props: ['filterTripsContent', 'locked', 'brandsVal'],
  data: function () {
    return {
      buttonActiveClass: 'is-active',
      brandsSelected: [],
      constants: searchConstants
    };
  },
  watch: {
    brandsVal(val) {
      this.brandsSelected = val;
    }
  },
  created() {
    eventBus.$on('brands-clear-values', () => {
      this.clearValues();
    });
    //this.brandsSelected = this.brandsVal;
  },
  methods: {
    isActive(brand) {
      let result = this.brandsSelected.includes(brand.brandName);
      return result;
    },
    selectedBrands(brand) {
      if (this.brandsSelected.includes(brand.brandName)) {
        let spliceKey = this.brandsSelected.indexOf(brand.brandName);
        this.brandsSelected.splice(spliceKey, 1);
      } else {
        this.brandsSelected.push(brand.brandName);
      }
      this.$parent.$emit('get-selected-brands', this.brandsSelected);
    }
  }
};
</script>
