<template>
  <div class="tourOptions_wrap">
    <div class="tourOptions-content-wrap">
      <div class="tourOptions-option-header">
        <p class="tourOptions-option-header-title">
          Trip
        </p>
        <p class="tourOptions-option-header-price">
          From Price
        </p>
        <p class="tourOptions-option-header-selected" />
      </div>
      <div
        v-for="(
          { tourId, tripName, price, originalPrice, tourOptionId, link }, index
        ) in tourOptionsData.slice(0, count)"
        :key="index"
        class="tourOptions-option"
        :data-tripId="tourId"
        tabindex="0"
        @click="sendTourId(tourOptionId, link)"
        @keyup.enter="sendTourId(tourOptionId, link)"
      >
        <div
          class="tourOptions"
          :class="{ 'is-selected ': isSelected === tourOptionId }"
        >
          <div class="tourOptions-data">
            <p class="tourOptions-title">
              {{ tripName }}
            </p>
            <p class="tourOptions-price">
              {{ price }}<br><span
                v-if="originalPrice"
                class="strikethrough"
              >{{
                originalPrice
              }}</span>
            </p>
          </div>
          <div class="tourOptions-status">
            <span class="icon-status">
              <i class="fa fa-check" />
            </span>
          </div>
        </div>
        <div
          v-if="isSelected === tourOptionId && tourLoading"
          class="tourFilters-section__loader"
        >
          <div class="loader-bar" />
        </div>
      </div>
    </div>
    <div
      v-if="!tourOptionsData.length"
      class="tourOptions__nodata"
    >
      Looks like we couldn't find any trips matching your search criteria. Please try again.
    </div>
    <button
      v-if="tourOptionsData.length > count"
      class="tourOptions__showmore"
      @click="showMore()"
    >
      Load more...
    </button>
  </div>
</template>

<script>
export default {
  props: ['touroptions', 'count', 'tourLoading'],
  emits: ['tourId, itineraryStatus'],
  data() {
    return {
      isSelected: window.Cms?.raqModal.tour.tourOptionId || null,
      tourUpdated: false
    };
  },
  computed: {
    tourOptionsData: function () {
      let withSelectedTourOptionId = this.touroptions.filter(
        (item) => item.tourOptionId === this.isSelected
      );
      let withOutSelectedTourOptionId = this.touroptions.filter(
        (item) => item.tourOptionId !== this.isSelected
      );
      return [...withSelectedTourOptionId, ...withOutSelectedTourOptionId];
    }
  },
  mounted() {},
  methods: {
    sendTourId(tourOptionId, tourLink) {
      this.isSelected = tourOptionId;

      this.$emit('tourId', tourLink);
      this.tourUpdate = true;

      this.$emit('itinerary-status', true);

      setTimeout(() => {
        const currentUrl = window.location.pathname;
        window.location.href = currentUrl.replace(/\/\d+$/, `/${tourOptionId}`);
      }, 2000);
    },
    showMore() {
      this.count += 5;
    }
  }
};
</script>
