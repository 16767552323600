<template>
  <Fragment>
    <img
      :src="imageSrc"
      :class="[imageDefaultClass, imageAdditionalClass]"
      :alt="imageAlt"
      :style="imageStyle"
      @mousedown="startDrag"
      @mouseup="stopDrag"
      @mousemove="drag"
      @mouseleave="stopDrag"
    />
    <map-modal-button
      :display-close-modal-button="displayCloseModalButton"
      :display-fullscreen-button="displayFullscreenButton"
      @scale-changed="handleUpdatedScale"
    />
  </Fragment>
</template>

<script>
import MapModalButton from '../../vue-components/map/map-button.vue';
import { Fragment } from 'vue-fragment';
export default {
  name: 'MapImageZoom',
  components: {
    'map-modal-button': MapModalButton,
    Fragment
  },
  props: [
    'displayCloseModalButton',
    'displayFullscreenButton',
    'imageSrc',
    'imageDefaultClass',
    'imageAdditionalClass',
    'imageAlt'
  ],
  data() {
    return {
      scale: 1,
      position: { x: 0, y: 0 },
      isDragging: false,
      lastMousePosition: { x: 0, y: 0 }
    };
  },
  computed: {
    imageStyle() {
      return {
        transform: `scale(${this.scale})`,
        left: `${this.position.x}px`,
        top: `${this.position.y}px`
      };
    }
  },
  methods: {
    handleUpdatedScale(updatedScale) {
      this.scale = updatedScale;
    },
    startDrag(event) {
      this.isDragging = true;
      this.lastMousePosition = { x: event.clientX, y: event.clientY };
      event.preventDefault();
    },
    stopDrag() {
      this.isDragging = false;
    },
    drag(event) {
      if (!this.isDragging || this.scale < 2) return;

      const dx = event.clientX - this.lastMousePosition.x;
      const dy = event.clientY - this.lastMousePosition.y;
      let newX = this.position.x + dx;
      let newY = this.position.y + dy;

      const parentElement = event.target.parentElement;
      const imgElement = event.target;

      const parentRect = parentElement.getBoundingClientRect();
      const imgRect = imgElement.getBoundingClientRect();

      const imgWidth = imgRect.width * this.scale;
      const imgHeight = imgRect.height * this.scale;

      const offsetX = imgWidth * 0.5;
      const offsetY = imgHeight * 0.5;

      const minX = parentRect.left - imgRect.left - offsetX;
      const maxX = parentRect.right - imgRect.right + offsetX;
      const minY = parentRect.top - imgRect.top - offsetY;
      const maxY = parentRect.bottom - imgRect.bottom + offsetY;

      newX = Math.max(minX, Math.min(newX, maxX));
      newY = Math.max(minY, Math.min(newY, maxY));

      this.position.x = newX;
      this.position.y = newY;

      this.lastMousePosition = { x: event.clientX, y: event.clientY };
    }
  }
};
</script>
