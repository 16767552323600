<template>
  <div class="secondarySubnav-wrapper">
    <div class="subnav-list">
      <vue-horizontal class="subnav-list-wrap horizontal">
        <template #btn-prev>
          <span class="direction-btn btn-prev" />
        </template>
        <template #btn-next>
          <span class="direction-btn btn-next" />
        </template>

        <div
          v-for="(item, index) in datamenu.navItems"
          :key="index"
          class="subnav-listItem"
        >
          <a
            :href="'#' + item.url"
            data-name="in-page-menu"
            :data-target="item.url"
            class="subnav-listItem__link"
          >{{ item.name }}</a>
        </div>
      </vue-horizontal>
    </div>
  </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal';
export default {
  components: {
    VueHorizontal
  },
  props: ['datamenu'],
  data() {
    return {};
  }
};
</script>
