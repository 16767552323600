<template>
  <section
    id="tour-filters-section"
    class="tourFilters-section section"
  >
    <div
      class="tourFilters-section-bg"
      :style="bgStyles"
    >
      <div class="tourFilters-section-filters">
        <h3 class="tourFilters-section-title">
          {{ filterConfiguration?.configuratorTitleText }}
        </h3>
        <p>
          {{ filterConfiguration?.configuratorDescriptionText }}
        </p>
        <br>
        <div
          v-if="tourfilters?.length"
          class="tour-tags"
        >
          <div class="tour-tags-filters first-filters">
            <div
              v-for="({ type, name, controlType, options }, index) in tourfilters"
              v-if="index < filterNumber"
              :key="type"
              class="tour-tags-opt searchbar-control"
              :data-tag="type"
            >
              <div
                class="tour-tag-select filter-checkbox"
                @keyup.enter="setFilterVisibility"
                @click="setFilterVisibility"
              >
                <div
                  tabindex="0"
                  class="dropdown-trigger"
                >
                  <span class="tour-tag">{{ name }}</span>
                  <p class="tour-tag-selection">
                    <span class="tour-tag-selection-tag">{{
                      filterLabel(filterForm[type], controlType)
                    }}</span>
                    <span class="icon is-small">
                      <i
                        aria-hidden="true"
                        class="fas fa-chevron-down"
                        :class="{
                          'fa-chevron-up': settings.showDurationFilter
                        }"
                      />
                    </span>
                  </p>
                </div>

                <div
                  class="dropdown-body"
                  :class="{
                    'is-open': settings.showDurationFilter,
                    'calender-body': controlType.toLowerCase() === 'calendar'
                  }"
                >
                  <div v-if="controlType.toLowerCase() === 'checkboxes'">
                    <div
                      v-for="value in options"
                      :class="{
                        selected: filterForm[type][0] === value
                      }"
                      class="checkbox-dropdown"
                    >
                      <label class="">
                        <input
                          v-model="filterForm[type]"
                          type="radio"
                          :value="value"
                          :name="filterForm[type]"
                          @change="onClickFilter(type, filterForm[type])"
                        >
                        {{ value }}
                      </label>
                    </div>
                  </div>

                  <div v-if="controlType.toLowerCase() === 'radiobutton'">
                    <div
                      v-for="value in options"
                      v-if="renderComponent"
                      :class="{
                        selected: filterForm[type][0] === value
                      }"
                      class="checkbox-dropdown"
                    >
                      <label class="">
                        <input
                          v-model="filterForm[type]"
                          type="radio"
                          :value="value"
                          :name="filterForm[type]"
                          @change="onClickFilter(type, filterForm[type])"
                        >
                        {{ value == 'true' ? 'Include' : 'Exclude' }}
                      </label>
                    </div>
                  </div>

                  <div v-if="controlType.toLowerCase() === 'calendar'">
                    <input
                      v-model="filterForm[type]"
                      type="hidden"
                    >
                    <departure-calendar
                      :yearmonths="options"
                      :selected="filterForm[type][0]"
                      @selected-month="getMonth"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clear" />
          <div class="clear-show-more">
            <p
              v-if="tourfilters.length > 4"
              class="show-more-button"
              tabindex="0"
              @click="showHideFilters"
              @keyup.enter="showHideFilters"
            >
              <span
                v-if="!isExpanded"
                tabindex="0"
              >Show more search options...</span>
              <span
                v-else
                tabindex="0"
              >Hide extended search options...</span>
            </p>
            <p
              :class="['show-more-button clear-all', !showClearAll ? 'disabled' : '']"
              tabindex="0"
              @click="clearAllFilters"
              @keyup.enter="clearAllFilters"
            >
              <span tabindex="0">Clear all</span>
            </p>
          </div>
          <div
            class="tour-tags-filters second-filters"
            :class="{ show: isExpanded }"
          >
            <div
              v-for="({ type, name, controlType, options }, index) in tourfilters"
              v-if="options !== 0 && index >= filterNumber"
              :key="type"
              class="tour-tags-opt searchbar-control"
              :data-tag="type"
            >
              <div
                class="tour-tag-select filter-checkbox"
                @keyup.enter="setFilterVisibility"
                @click="setFilterVisibility"
              >
                <div
                  tabindex="0"
                  class="dropdown-trigger"
                >
                  <span class="tour-tag">{{ name }}</span>
                  <p class="tour-tag-selection">
                    <span class="tour-tag-selection-tag">{{
                      filterLabel(filterForm[type], controlType)
                    }}</span>
                    <span class="icon is-small">
                      <i
                        aria-hidden="true"
                        class="fas fa-chevron-down"
                      />
                      <i
                        aria-hidden="true"
                        class="fas fa-chevron-up"
                        style="display: none"
                      /></span>
                  </p>
                </div>

                <div class="dropdown-wrap">
                  <div
                    class="dropdown-body"
                    :class="{
                      'is-open': settings.showDurationFilter,
                      'calender-body': controlType.toLowerCase() === 'calendar'
                    }"
                  >
                    <div v-if="controlType.toLowerCase() === 'checkboxes'">
                      <div
                        v-for="value in options"
                        :class="{
                          selected: filterForm[type][0] === value
                        }"
                        class="checkbox-dropdown checkbox-dropdownChekOnly"
                      >
                        <label class="">
                          <input
                            v-model="filterForm[type]"
                            type="radio"
                            :value="value"
                            :name="filterForm[type]"
                            @change="onClickFilter(type, filterForm[type][0])"
                          >
                          {{ value }}
                        </label>
                      </div>
                    </div>
                    <div v-if="controlType.toLowerCase() === 'radiobutton'">
                      <div
                        v-for="value in options"
                        v-if="renderComponent"
                        :class="{
                          selected: filterForm[type][0] === value
                        }"
                        class="checkbox-dropdown"
                      >
                        <label class="">
                          <input
                            v-model="filterForm[type]"
                            type="radio"
                            :value="value"
                            :name="filterForm[type]"
                            @change="onClickFilter(type, filterForm[type])"
                          >
                          {{ value == 'true' ? 'Include' : 'Exclude' }}
                        </label>
                      </div>
                    </div>
                    <div v-if="controlType.toLowerCase() === 'calendar'">
                      <input
                        v-model="filterForm[type]"
                        type="hidden"
                      >
                      <departure-calendar
                        :yearmonths="options"
                        :selected="filterForm[type][0]"
                        @selected-month="getMonth"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clear" />
          <div
            v-if="showMessage"
            class="tourFilters-section__message"
          >
            <span><i class="fas fa-exclamation" /></span>
            <p>
              Your filters did not match any results, so we changed them to display the results
              closest to your criteria
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showLoader"
      class="tourFilters-section__loader"
    >
      <div class="loader-bar" />
    </div>
  </section>
</template>

<script>
import DepartureCalendar from './departure-calendar.vue';
export default {
  components: {
    'departure-calendar': DepartureCalendar
  },
  props: ['tourfilters', 'showLoader', 'filterConfiguration', 'touroptions'],
  data() {
    return {
      filterNumber: 4,
      showClearAll: false,
      showMessage: false,
      renderComponent: true,
      filterForm: {
        'itinerary:reverse': [],
        'itinerary:start': [],
        'itinerary:end': [],
        Departure: [],
        'itinerary:transport': [],
        itinerary: [],
        theme: [],
        accommodation: [],
        'accommodation:train': [],
        'itinerary:bonus-night': [],
        'offers:air': [],
        'itinerary:cruise': [],
        'accommodation:cruise': [],
        SpecialAirDeals: []
      },
      saveObj: {},
      settings: {
        showDurationFilter: false,
        filterVisibility: false,
        filtersValues: {
          monthsVal: false
        }
      },
      isExpanded: false
    };
  },
  computed: {
    bgStyles() {
      return {
        background: `#${this.filterConfiguration?.configuratorBackgroundColor}`
      };
    }
  },
  mounted() {
    this.removeFilterVisibility();
    this.hideFilter();
    let hasWidget = document.querySelector('.content-container')?.classList.contains('has-widget');
    if (hasWidget) {
      this.filterNumber = 3;
    }
  },
  methods: {
    isTypeOptions(obj, tagsArray) {
      let objLength = Object.keys(obj).length;
      let resultLength = tagsArray.filter(({ type, options }) => {
        for (let ob in obj) {
          if (ob == type) {
            return options.includes(obj[ob][0]);
          }
        }
      }).length;
      return objLength == resultLength;
    },
    onClickFilter(filterType, value) {
      let newObj = {};
      let tags = [];
      if (value) {
        this.saveObj[filterType] = [event.currentTarget.value];
        let newFilterArray = [...this.touroptions].filter(({ tags }) =>
          this.isTypeOptions(this.saveObj, tags)
        );
        if (newFilterArray.length > 0) {
          for (let ob in this.saveObj) {
            this.filterForm[ob] = this.saveObj[ob];
          }
        } else {
          tags = [...this.touroptions].filter(({ tags }) =>
            tags.some(({ type, options }) => type === filterType && options.includes(value))
          )[0].tags;
          tags.forEach(({ type, options }) => {
            let formValue = [options[0]];
            if (['true', 'false'].includes(options[0])) {
              formValue = options[0];
            }
            if (this.saveObj.hasOwnProperty(type)) {
              newObj[type] = formValue;
            }
          });

          if (filterType === 'Departure') {
            newObj[filterType] = [value];
          }
          for (let ob in newObj) {
            this.filterForm[ob] = newObj[ob];
          }
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
          }, 32000);
        }
      } else {
        delete this.saveObj[filterType];
        delete newObj[filterType];
        if (filterType === 'Departure') {
          this.filterForm['Departure'] = [];
        }
      }

      this.getSelectedFilters();
      this.checkBoxDisabled(event, value);
    },
    getSelectedFilters() {
      let filterData = {};
      for (let ob in this.filterForm) {
        if (this.filterForm[ob].length > 0) {
          filterData[ob] = `${
            Array.isArray(this.filterForm[ob]) ? this.filterForm[ob][0] : this.filterForm[ob]
          }`;
        }
      }
      this.$emit('send-filters-data', filterData);
      this.showClearAll = Object.keys(filterData).length;
    },
    getMonth(month) {
      this.onClickFilter('Departure', month[0]);
    },
    setFilterVisibility(event) {
      event.stopPropagation();
      if (event.target.className === 'dropdown-trigger') {
        let filterClicked = event.target.parentNode;
        let getTourTagsOpt = document.querySelectorAll('.tour-tags-filters .tour-tags-opt');
        let currentTag = filterClicked.parentNode.getAttribute('data-tag');
        getTourTagsOpt.forEach((element) => {
          let elementTagName = element.getAttribute('data-tag');
          if (elementTagName !== currentTag) {
            element.children[0].classList.remove('is-active');
          }
        });
        filterClicked.classList.toggle('is-active');
      }
    },
    removeFilterVisibility() {
      document.addEventListener('click', (event) => {
        let isClickInside = event.target.classList.contains('');

        if (!isClickInside) {
          let filterVisObj = this.settings.showDurationFilter;
          let hidefilters = false;

          Object.keys(filterVisObj).forEach(function (key) {
            if (filterVisObj[key] === true) {
              hidefilters = true;
            }
          });

          if (hidefilters) {
            this.setFilterVisibility();
          }
        }
      });
    },
    showHideFilters() {
      this.isExpanded = !this.isExpanded;
    },
    hideFilter() {
      document.addEventListener('click', function (e) {
        let getTourTagsOpt = document.querySelectorAll('.tour-tags-filters .tour-tags-opt');
        getTourTagsOpt.forEach((element) => {
          let elementTagName = element.getAttribute('data-tag');
          element.children[0].classList.remove('is-active');
        });
      });
    },
    checkBoxDisabled(event, value) {
      let current = event.currentTarget.parentNode.parentNode.parentNode.children;
      if (event.currentTarget.type === 'checkbox') {
        for (let i = 0; i < current.length; i++) {
          current[i].querySelector('[type=checkbox]').checked = false;
        }
        event.currentTarget.checked = true;
      }
    },
    filterLabel(filters, controlType) {
      let allMonths = [
        '',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      let label = 'Select...';
      if (filters?.length > 0) {
        if (controlType.toLowerCase() === 'calendar') {
          label =
            allMonths[parseInt(filters[0].split('/')[0], 10)] + ' ' + filters[0].split('/')[1];
        } else if (controlType.toLowerCase() === 'radiobutton') {
          label = filters == 'true' ? 'Include' : 'Exclude';
        } else {
          label = filters[0];
        }
      }
      return label;
    },
    clearAllFilters() {
      this.renderComponent = false;
      for (let ob in this.filterForm) {
        this.filterForm[ob] = [];
      }
      this.saveObj = {};
      this.getSelectedFilters();
      let clearTimeOut = setTimeout(() => {
        this.renderComponent = true;
      }, 0);
      if (this.renderComponent) {
        clearTimeout(clearTimeOut);
      }
      this.showMessage = false;
    }
  }
};
</script>
