<template>
  <section class="searchlistings">
    <transition name="fade">
      <div v-if="settings.totalResults || settings.showMoreLoading">
        <article
          v-for="(product, productIndex) in products"
          :key="'product' + productIndex"
          class="ttc-search-items"
        >
          <div v-if="product.results.length > 0">
            <div
              v-for="(result, resultIndex) in tourResults"
              :key="'tourResult' + resultIndex"
              class="searchlistings__listing"
            >
              <div class="columns image-container">
                <div class="searchlistings__listing__image column is-3-tablet">
                  <a
                    v-if="result.primaryImageUrl"
                    target="_blank"
                    aria-label="image"
                    class="searchlistings__listing__image__link"
                    :href="result.url"
                    :style="[
                      !result.isCmsTour
                        ? {
                            backgroundImage: 'url(\'' + result.primaryImageUrl + '\')',
                            backgroundPosition: '50%'
                          }
                        : {
                            backgroundImage: 'url(\'' + result.primaryImageUrl + '\')',
                            backgroundPosition:
                              '' + result.leftFocalPoint + ' ' + result.topFocalPoint + ''
                          }
                    ]"
                  >
                    <img
                      class="is-background mobile-visible"
                      :src="result.primaryImageUrl"
                      :alt="
                        result.primaryImageAltText ? result.primaryImageAltText : result.tourName
                      "
                    />
                  </a>
                  <a
                    v-if="!result.primaryImageUrl"
                    target="_blank"
                    :href="result.url"
                    aria-label="image"
                  >
                    <img
                      class="is-background"
                      :src="settings.searchResultContent.alternativeSearchImage"
                      :alt="
                        settings.searchResultContent.alternativeSearchImageAltText
                          ? settings.searchResultContent.alternativeSearchImageAltText
                          : 'search-image-placeholder-image'
                      "
                    />
                  </a>
                  <div
                    v-if="result?.groupSizeText"
                    class="group-container"
                    :class="{ showGroupSize: result.hasGroupSizeVariants }"
                  >
                    <img
                      :src="settings.searchResultContent.groupSizeImage"
                      :alt="
                        settings.searchResultContent.groupSizeImageAltText
                          ? settings.searchResultContent.groupSizeImageAltText
                          : 'Group Size logo'
                      "
                      class="image group-container-image"
                    />
                    <span class="group-container-text">{{ result?.groupSizeText }}</span>
                  </div>
                  <div
                    v-if="result?.smallGroupText"
                    class="group-container"
                    :class="{
                      showSmallGroup: result.isSmallGroup && !result.hasGroupSizeVariants
                    }"
                  >
                    <img
                      :src="settings.searchResultContent.smallGroupsImage"
                      :alt="
                        settings.searchResultContent.smallGroupsImageAltText
                          ? settings.searchResultContent.smallGroupsImageAltText
                          : 'small Groups Image logo'
                      "
                      class="image group-container-image"
                    />
                    <span class="group-container-text">{{ result?.smallGroupText }}</span>
                  </div>
                  <div class="searchlisting-brand-image-ss">
                    <img
                      :src="locationString + result.brand.logoUrl"
                      :alt="
                        result.brand.logoAltText
                          ? result.brand.logoAltText
                          : result.brand.brandValue + ' logo'
                      "
                      class="image is-paddingless"
                      :class="{
                        'is-square': brandLogoSizeArray[result.brand.brandValue]
                      }"
                    />
                  </div>
                </div>
                <div class="searchlistings__listing__desc column is-relative">
                  <div
                    v-if="result.travelTypes != null && hideType"
                    class="searchlisting-travel-style"
                  >
                    <div class="level-left">
                      <p class="category">
                        {{ settings.searchResultContent.travelTypeText }}
                      </p>
                      <div>
                        <div class="searchlisting__item">
                          {{ result.travelTypes.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="result.travelStyles" class="searchlisting-travel-style">
                    <span
                      v-for="(travelStyle, travelStyleIndex) in result.travelStyles"
                      :key="'travelStyle' + travelStyleIndex"
                      class="searchlisting__item searchlisting__item--background"
                    >
                      {{ travelStyle }}
                    </span>
                  </div>
                  <a target="_blank" :href="result.url" class="searchlisting-tour-title">
                    <h3 v-html="result.tourName" />
                  </a>
                  <div class="column search-listing-brand-image-ls">
                    <img
                      :src="locationString + result.brand.logoUrl"
                      :alt="
                        result.brand.logoAltText
                          ? result.brand.logoAltText
                          : result.brand.brandValue + ' image'
                      "
                      class="image"
                    />
                    <button
                      v-if="result.showAboutUs && result.aboutUsTextLink != ''"
                      class="searchlisting-aboutUs"
                      @click="
                        LaunchGenericModal({
                          logoUrl: result.brand.logoUrl,
                          logoAlt: result.brand.logoAltText,
                          aboutUsText: result.aboutUsModalText
                        })
                      "
                    >
                      <span class="fas fa-info-circle" />{{ result.aboutUsTextLink }}
                    </button>
                  </div>

                  <div class="searchlisting-tour-data isLoggedOut">
                    <div v-if="result.numberOfDays" class="searchlisting-tour-data-item">
                      <i class="fas fa-calendar" />
                      <span class="searchlisting__item"> {{ result.numberOfDays }} days </span>
                    </div>

                    <search-location-item
                      :settings="settings"
                      :result="result"
                      :cities-count="result?.locationsVisited?.split(',').length"
                    />

                    <search-country-item
                      :settings="settings"
                      :result="result"
                      :country-count="result?.countriesVisited?.split(',').length"
                    />
                  </div>

                  <div v-if="result.airfareIncluded" class="searchlisting-tour-airfareIncludedText">
                    <i class="fa fa-plane" />
                    <span class="searchlisting__item">
                      {{ result.airfareIncludedText }}
                    </span>
                  </div>

                  <a
                    v-if="result.hasVariants && !!settings.searchResultContent.tripVariantsText"
                    :href="`${result.url}/#trip-options-section`"
                    class="searchlisting-more-options"
                    target="_blank"
                  >
                    <p class="more-options-text">
                      {{ settings.searchResultContent.tripVariantsText }}
                    </p>
                  </a>
                </div>
                <div class="searchlistings__listing__pricing column">
                  <div class="columns searchlisting-pricing-wrapper">
                    <div class="column">
                      <p class="pricefrom">
                        {{ settings.searchResultContent.priceFromText }}
                      </p>
                      <div class="columns priceholder">
                        <p class="price column">
                          {{ product.currencySymbol }}{{ result.fromAdultPrice.toLocaleString() }}
                          <span v-if="result.airfareIncluded">*</span
                          ><span v-if="result.cmsCurrency" class="is-size-7"
                            >&nbsp;{{ currency(result.cmsCurrency) }}</span
                          >
                        </p>
                        <p
                          v-if="
                            result.originalAdultPrice &&
                            result.fromAdultPrice != result.originalAdultPrice &&
                            regionalSettings.displayOriginalAdultPrice
                          "
                          class="pricewas column"
                        >
                          <span class="">
                            <span class="strikethrough">
                              {{ product.currencySymbol
                              }}{{ result.originalAdultPrice.toLocaleString() }}
                            </span>
                            <span v-if="result.cmsCurrency" class="is-size-7"
                              >&nbsp;{{ currency(result.cmsCurrency) }}</span
                            >
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p
                    v-if="
                      result.brand.showCallToAction &&
                      product.loginCallToAction.isEnabled &&
                      !product.loginCallToAction.isLoggedIn
                    "
                    class="login-for-price-call-to-action"
                  >
                    <img
                      :src="locationString + product.loginCallToAction.logoUrl"
                      :alt="result.brand.logoAltText"
                      class="logo-image"
                    />
                    <a
                      id="customer-price-link"
                      :href="product.loginCallToAction.loginUrl"
                      class="login-link"
                      type="submit"
                      @click="dataLayerLoginForPriceClicked()"
                    >
                      {{ product.loginCallToAction.text }}
                    </a>
                  </p>
                  <div class="buttonholder level searchlisting-cta-wrapper">
                    <div class="level-left">
                      <a target="_blank" class="button is-primary is-level cta" :href="result.url"
                        >{{ settings.searchResultContent.viewVacationButtonText }}
                      </a>
                      <button
                        data-type="booking interest"
                        data-name="raq"
                        class="button is-subtle is-level cta"
                        @click="
                          launchEasyQuote(
                            result.brand.brandValue,
                            result.brand.name,
                            result.tourName,
                            result.aaeCategory,
                            result.isCmsTour,
                            result.tourDate,
                            $event,
                            result.emailLead,
                            result.url,
                            result.tourId,
                            result.tourOptionId,
                            result.isUniworldTour,
                            result.isUbyTour
                          )
                        "
                      >
                        {{ settings.searchResultContent.quoteButtonText }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </transition>
    <div class="is-relative">
      <div v-if="settings.showMoreLoading" class="search-results-show-more">
        <div class="loader-bar" />
      </div>
      <div
        v-if="
          !settings.loading &&
          !settings.showMoreLoading &&
          settings.parentSearch &&
          settings.resultsInView < settings.totalResults
        "
        class="search-results-show-more"
      >
        <div class="inner">
          <button
            class="button is-subtle is-outlined cta"
            aria-label="ShowMore"
            @click="$emit('show-more-results')"
          >
            {{ settings.searchResultContent.showMore }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="!settings.loading && !settings.parentSearch && !settings.error"
      class="search-results-go-main"
    >
      <div class="inner">
        <button
          data-type="general interaction"
          data-name="button"
          class="button is-subtle is-outlined is-fullwidth"
          aria-label="MoreResults"
          @click="$emit('exit-to-main-search')"
        >
          <p>{{ settings.searchResultContent.searchMoreResults }}</p>
          <span>{{ settings.searchResultContent.opensMainSearch }}</span>
        </button>
      </div>
    </div>

    <div v-if="settings.loading && settings.totalResults === 0 && !settings.showMoreLoading">
      <div v-if="!settings.error" class="search__loading-loader">
        <div class="loader-bar is-marginless" />
      </div>
      <div class="search__loading">
        <h3
          v-text="
            settings.searchResultContent.loadingMessage
              ? settings.searchResultContent.loadingMessage
              : 'We\'re searching for the right trips. Please wait while we find your results.'
          "
        />
      </div>
    </div>
    <div v-if="!settings.loading && settings.totalResults === 0" class="search__loading">
      <h3
        v-text="
          settings.searchResultContent.noSearchResultsMessage
            ? settings.searchResultContent.noSearchResultsMessage
            : 'Looks like we couldn\'t find any trips matching your search criteria'
        "
      />
    </div>
  </section>
</template>

<script>
import searchCountryItem from '../../vue-components/search/search-country-item.vue';
import searchLocationItem from '../../vue-components/search/search-location-item.vue';
import * as searchConstants from '../../search-constants.js';
import EventBus from '../../vue-functions/event-bus';
export default {
  components: {
    searchCountryItem,
    searchLocationItem
  },
  props: {
    settings: {
      type: Object
    },
    products: {
      type: Array
    },
    tourResults: {
      type: Array
    },
    locationString: {
      type: String
    },
    regionalSettings: Object
  },
  data() {
    return {
      brandLogoSizeArray: {},
      constants: searchConstants,
      hideType: false,
      showModal: false,
      modalData: ''
    };
  },
  computed: {},
  created() {
    this.populateBrandLogoSize();
    //this.openModal();
  },
  methods: {
    populateBrandLogoSize() {
      let obj = {};
      for (let i = 0; i < this.settings.filterTripsContent.vacationBrand.brands.length; i++) {
        let brandKey = this.settings.filterTripsContent.vacationBrand.brands[i].brandId;
        let brandVal = this.settings.filterTripsContent.vacationBrand.brands[i].isSquare;
        obj[brandKey] = brandVal;
      }
      this.brandLogoSizeArray = obj;
    },
    currency(cmsCurrency) {
      if (!cmsCurrency) {
        return '';
      } else {
        return cmsCurrency;
      }
    },
    dataLayerLoginForPriceClicked() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'Login Clicked From Search Results' });
    },
    launchEasyQuote(
      brand,
      brandName,
      tourName,
      aaeCategory,
      isCmsTour,
      tourDate,
      event,
      emailLead,
      url,
      tourId,
      tourOptionId,
      isUniworldTour,
      isUbyTour
    ) {
      let tour = {
        brand,
        brandName,
        tourName,
        aaeCategory,
        isCmsTour,
        url,
        tourId,
        tourOptionId,
        isUniworldTour,
        isUbyTour
      };
      this.$emit('launch-easy-quote', tour);
      const target = event.target;
      EventBus.$emit('update-raq-target', target);
      EventBus.$emit('update-is-email-lead', emailLead);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'RAQ Button Clicked From Search' });
    },
    LaunchGenericModal(data) {
      EventBus.$emit('toggle-aboutus-modal', '');
      EventBus.$emit('update-aboutus-target', event.target);
      EventBus.$emit('aboutus-modal-data', data);
    }
  }
};
</script>
