import Vue from 'vue';
import FeaturedParentSection from '../vue-components/featured/featured-parent-section.vue';

function featuredParentSectionView() {
  new Vue({
    el: '#featured-parent-section',
    components: {
      'featured-parent-section': FeaturedParentSection
    },
    data() {
      return {
        featuredsection: window.Cms.featuredSection
      };
    }
  });
}
export default featuredParentSectionView;
