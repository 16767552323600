import * as searchConstants from '../../search-constants.js';

function getDurationSliderValues(valObj) {
  // function expects an object with 1 or 2 values minDuration, and or maxDuration
  // if those values are set in the object
  // we set the price filter and it's associated vales to those received
  if (valObj.minDuration) {
    this.$data.settings.filtersValues.minDurationVal = parseInt(valObj.minDuration);
    this.$data.settings.filters.minDuration =
      searchConstants.MINDURATIONSEARCHPREFIX + valObj.minDuration;
  }

  if (valObj.maxDuration) {
    this.$data.settings.filtersValues.maxDurationVal = parseInt(valObj.maxDuration);
    this.$data.settings.filters.maxDuration =
      searchConstants.MAXDURATIONSEARCHPREFIX + valObj.maxDuration;
  }

  // if the values are not present in the object, we reset the filter values back to false
  if (!valObj.maxDuration) {
    this.$data.settings.filtersValues.maxDurationVal = false;
    this.$data.settings.filters.maxDuration = false;
  }

  if (!valObj.minDuration) {
    this.$data.settings.filtersValues.minDurationVal = false;
    this.$data.settings.filters.minDuration = false;
  }

  //  kick off the function to set the filter button text based on the object
  this.setDurationFilterText(valObj);
}
export default getDurationSliderValues;
