import Vue from 'vue';
import HeroGeneric from '../../vue-components/heros/hero-generic.vue';

function heroGenericView() {
  new Vue({
    el: '#hero-generic-section',
    components: {
      'hero-generic': HeroGeneric
    },
    data() {
      return {
        heroModel: window.Cms.heroModel
      };
    }
  });
}
export default heroGenericView;
