function filterSearch(combineResultsRequired = false) {
  // if we are not in a show more scenario, reset the search
  if (!combineResultsRequired) {
    this.resetSearch();
  }

  let filterString = this.constructSearchTerm();
  let activeFilters = this.makeSearchFilters();

  if (activeFilters.length > 0) {
    activeFilters.map((val) => {
      filterString += '&' + val;
    });
  }

  if (this.$data.settings.parentSearch) {
    this.manageUrlState(filterString);
  }

  this.fetchSearch(filterString, combineResultsRequired);
  // clear the search field if we are in a filter search
  if (this.$refs.searchfield) {
    this.$refs.searchfield.resetFreeTextField();
  }
  this.setFilterVisibility();
}
export default filterSearch;
