<template>
  <div>
    <portal
      v-if="targetel"
      :name="name"
      :target-el="'#' + targetel"
      slim
    >
      <button
        data-type="booking interest"
        data-name="raq"
        class="button is-subtle cta"
        @click.prevent="
          [toggleModal(tour, tourselecteddate, tourdepartureid, $event), dataLayerRAQOpen()]
        "
        v-text="labels.button"
      />
    </portal>
    <button
      v-if="!targetel"
      class="button is-subtle is-pulled-right cta"
      @click.prevent="
        [toggleModal(tour, tourselecteddate, tourdepartureid, $event), dataLayerRAQOpen()]
      "
      v-text="labels.button"
    />
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  props: {
    tour: Object,
    labels: Object,
    tourselecteddate: String,
    tourdepartureid: String,
    name: String,
    targetel: String
  },
  methods: {
    toggleModal(tour, tourselecteddate, tourdepartureid, event) {
      EventBus.$emit('TOGGLE_RAQ_MODAL', tour, tourselecteddate, tourdepartureid);
      const target = event.target;
      EventBus.$emit('update-raq-target', target);
    },
    dataLayerRAQOpen() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'RAQ Button Clicked From Trip' });
    }
  }
};
</script>
