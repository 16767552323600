import Vue from 'vue';
import VueDeals from '../vue-components/deals/deals-list.vue';

function websiteOngoingDeals() {
  new Vue({
    el: '#app-ongoing-deals',
    components: {
      'vue-deals': VueDeals
    },
    data: function () {
      return window.Cms.deals;
    },
    beforeCreate: function () {
      if (typeof this.ongoingDeals === 'undefined') {
        this.ongoingDeals = {};
      }
    }
  });
}
export default websiteOngoingDeals;
