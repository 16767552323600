import getCurrentScreenSize from '../ui-functions/get-current-screen-size';

const mobileMenu = () => {
  const nav = document.querySelector('#navbarMenu');
  const navParent = document.querySelector('.navbar');

  if (!navParent) return;

  const navActiveClass = 'is-active';
  const mobileActiveClass = 'is-mobile-nav-active';
  const searchActiveClass = 'is-mobile-search-active';
  const bodyEl = document.querySelector('body');
  const navActiveBodyClass = 'is-nav-active';

  let btnLastFired = null;

  const mobileNavBtn = document.querySelector('.navbar-burger');
  const mobileSearchBtn = document.querySelector('.navbar-mobile-search-btn');
  const navParents = document.querySelectorAll('.navbar-item.has-dropdown');
  const mobileBackBtns = document.querySelectorAll('.navbar-mobile-go-back');

  // Handle mobile navigation button click
  mobileNavBtn.addEventListener('click', (event) => {
    if (btnLastFired === event.currentTarget) {
      nav.classList.toggle(navActiveClass);
      bodyEl.classList.toggle(navActiveBodyClass);
    } else {
      nav.classList.add(navActiveClass);
      bodyEl.classList.add(navActiveBodyClass);
    }

    navParent.classList.toggle(mobileActiveClass);
    navParent.classList.remove(searchActiveClass);
    btnLastFired = event.currentTarget;
  });

  // Handle mobile search button click
  mobileSearchBtn.addEventListener('click', () => {
    if (btnLastFired === event.currentTarget) {
      nav.classList.toggle(navActiveClass);
    } else {
      nav.classList.add(navActiveClass);
    }

    navParent.classList.toggle(searchActiveClass);
    navParent.classList.remove(mobileActiveClass);
    bodyEl.classList.remove(navActiveBodyClass);

    btnLastFired = event.currentTarget;
  });

  // Handle mobile back button clicks
  mobileBackBtns.forEach(currBtn => {
    currBtn.addEventListener('click', () => {
      currBtn.parentNode.parentNode.classList.remove(navActiveClass);
    });
  });

  // Function to reset navigation states
  const resetNavs = () => {
    nav.classList.remove(navActiveClass);
    navParent.classList.remove(searchActiveClass);
    navParent.classList.remove(mobileActiveClass);
    bodyEl.classList.remove(navActiveBodyClass);

    navParents.forEach(currBtn => {
      currBtn.classList.remove(navActiveClass);
    });
  };

  // Handle window resize event
  window.addEventListener('resize', () => {
    const currSs = getCurrentScreenSize();

    if (currSs === 'ls' || currSs === 'xls') {
      resetNavs();
    }
  });
};

export default mobileMenu;
