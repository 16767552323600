<template>
  <div
    id="ftcmodal"
    class="modal modal-geq"
    :class="{ 'is-active': active }"
  >
    <div
      class="modal-background"
      @click.prevent="closeForm()"
    />
    <div class="modal-card">
      <div
        v-show="!submitted"
        class="modal-form"
      >
        <div class="modal-card-head">
          <p class="modal-card-title">
            {{ labels.modalTitle }}
          </p>
          <a
            class="modal-card-close bg-transparent"
            href="#"
            aria-label="close"
            @click.prevent="closeForm()"
          >
            <i class="fa fa-times fa-lg" />
          </a>
        </div>
        <div class="modal-card-image">
          <img
            class="is-background"
            :src="labels.backgroundImage"
            :alt="
              labels.backgroundImageAltText
                ? labels.backgroundImageAltText
                : 'modal-background-image'
            "
          >
        </div>
        <form @submit.prevent="validateBeforeSubmit">
          <section class="modal-card-body">
            <h2 v-text="labels.modalHeading" />
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label
                    class="label"
                    :class="{ 'required-label': labels.isFirstNameMandatory }"
                  >
                    {{ labels.labelFirstname }}
                  </label>
                  <div class="control">
                    <input
                      id="ftcfirstname"
                      v-model="quoterequest.firstname"
                      :data-vv-as="labels.labelFirstname"
                      aria-label="labelfirstname"
                      name="firstname"
                      :v-validate="firstNameValidationRules"
                      class="input input-subtle"
                      type="text"
                    >
                    <span
                      v-if="errors.has('firstname')"
                      class="field-validation-error"
                    >{{
                      errors.first('firstname')
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelLastname }}</label>
                  <div class="control">
                    <input
                      id="ftclastname"
                      v-model="quoterequest.lastname"
                      v-validate="'required'"
                      :data-vv-as="labels.labeLastname"
                      aria-label="labellastname"
                      name="lastname"
                      class="input input-subtle"
                      type="text"
                    >
                    <span
                      v-if="errors.has('lastname')"
                      class="field-validation-error"
                    >{{
                      errors.first('lastname')
                    }}</span>
                  </div>
                </div>
              </div>
              <input
                id="toemailaddress"
                name="toemailaddress"
                type="hidden"
                v-text="quoterequest.toemailaddress"
              >
              <input
                id="ccemailaddress"
                name="ccemailaddress"
                type="hidden"
                v-text="quoterequest.ccemailaddress"
              >
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelEmail }}</label>
                  <div class="control">
                    <input
                      id="ftcEmail"
                      v-model="quoterequest.email"
                      v-validate="'required|email'"
                      :data-vv-as="labels.labelEmail"
                      aria-label="labelEmail"
                      name="Email"
                      class="input input-subtle"
                      type="email"
                      novalidate
                    >
                    <span
                      v-if="errors.has('Email')"
                      class="field-validation-error"
                    >{{
                      errors.first('Email')
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label
                    class="label"
                    v-text="labels.labelPhone"
                  />
                  <div class="control">
                    <input
                      id="ftcPhone"
                      v-model="quoterequest.phone"
                      :placeholder="labels.placeholderOptional"
                      aria-label="labelPhone"
                      name="Phone"
                      class="input input-subtle"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="labels.showZipCode"
              class="columns"
            >
              <div class="column">
                <div class="field">
                  <label class="label required-label">
                    {{ labels.labelZipOrPostalCode }}
                  </label>
                  <div class="control">
                    <input
                      id="zipCode"
                      v-model="quoterequest.zipCode"
                      v-validate="zipCodeValidationRules"
                      name="zipCode"
                      aria-label="labelZipCode"
                      class="input input-subtle"
                    >
                    <input
                      id="showZipCode"
                      name="showZipCode"
                      type="hidden"
                      v-text="labels.showZipCode"
                    >
                    <span
                      v-if="errors.has('zipCode')"
                      class="field-validation-error"
                    >{{
                      errors.first('zipCode')
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="column" />
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">{{ labels.labelComments }}</label>
                  <div class="control">
                    <textarea
                      v-model="quoterequest.comments"
                      type="text"
                      aria-label="labels.placeholderComments"
                      :placeholder="labels.placeholderComments"
                      class="input input-subtle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="modal-card-foot">
            <button
              type="submit"
              class="button is-secondary show-confirm"
            >
              {{ labels.submitButton }}
            </button>
          </div>
        </form>
      </div>
      <div
        v-show="submitted"
        id="model-confirm-ftc"
        class="modal-confirm"
      >
        <div class="modal-card-head">
          <p class="modal-card-title">
            {{ labels.modalTitle }}
          </p>
        </div>
        <div class="modal-card-image">
          <img
            :src="labels.submittedBackgroundImage"
            :alt="
              labels.submittedBackgroundImageAltText
                ? labels.submittedBackgroundImageAltText
                : 'modal-confirm-image'
            "
            class="is-background"
          >
          <div class="card-content">
            <h2 v-show="!submittederror">
              {{ labels.submittedTitle }}
            </h2>
            <h2 v-show="submittederror">
              {{ labels.submittedErrorTitle }}
            </h2>
          </div>
        </div>
        <div class="modal-card-body">
          <p v-show="!submittederror">
            {{ labels.submittedText }}
          </p>
          <p v-show="submittederror">
            {{ labels.submittedErrorText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
import axios from 'axios';
import { closeModal } from '../../modals/closeModal';
export default {
  props: ['labels', 'api', 'pageid', 'url', 'toemailaddress', 'ccemailaddress'],
  data() {
    return {
      active: false,
      modal: false,
      submitted: false,
      submittederror: false,
      quoterequest: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        zipCode: '',
        showZipCode: false,
        comments: '',
        toemailaddress: '',
        ccemailaddress: ''
      }
    };
  },
  computed: {
    firstNameValidationRules() {
      return this.labels.isFirstNameMandatory ? 'required|max:50' : 'max:50';
    },
    zipCodeValidationRules() {
      return this.labels.showZipCode ? 'required|alpha_dash|max: 9' : 'alpha_dash|max: 9';
    }
  },
  watch: {
    submitted() {
      this.dataLayer();
    }
  },
  created: function () {
    EventBus.$on('TOGGLE_FTC_MODAL', () => {
      document.documentElement.classList.add('is-clipped');
      this.show();
    });

    this.quoterequest.toemailaddress = this.toemailaddress;
    this.quoterequest.ccemailaddress = this.ccemailaddress;
    this.quoterequest.showZipCode = this.labels.showZipCode;
  },
  mounted() {
    this.closeModal(this.closeForm);
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.handleSubmit();
        }
      });
    },
    show() {
      this.active = true;
    },
    submittedForm() {
      this.submitted = true;
    },
    closeForm() {
      document.documentElement.classList.remove('is-clipped');
      this.submitted = false;
      this.active = false;
    },
    handleSubmit() {
      self = this;
      axios
        .post(this.api, this.quoterequest, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function () {
          self.submittederror = false;
          self.submittedForm();
        })
        .catch(function () {
          self.submittederror = true;
          self.submittedForm();
        });
    },
    closeModal,
    dataLayer() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'FTC Modal Submitted' });
    }
  }
};
</script>
