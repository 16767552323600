<template>
  <div
    id="geqmodalsite"
    class="modal modal-geq"
    :class="{ 'is-active': active }"
  >
    <div class="modal-background" />
    <div class="modal-card">
      <div class="modal-form">
        <div class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="content.title"
          />
        </div>
        <form @submit.prevent="closeForm">
          <section class="modal-card-body">
            <h2 v-text="content.heading" />
            <div v-html="content.content1" />
            <div v-html="content.content2" />
          </section>
          <div class="modal-card-foot">
            <button
              type="submit"
              class="button is-secondary show-confirm"
              :class="{ 'width-auto': showCancelButton() }"
              v-text="content.submitButtonText"
            />
            <button
              v-if="showCancelButton()"
              type="button"
              class="button width-auto has-background-white show-confirm"
              @click="redirect(content.cancelRedirectUrl)"
              v-text="content.cancelButtonText"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import VueCookies from 'vue-cookies';

export default {
  props: ['display', 'content'],
  data() {
    return {
      active: false,
      agreeTerms: false
    };
  },
  created: function () {
    if (this.display) {
      if (!VueCookies.get('AgreeTerms')) this.show();
    }
  },
  methods: {
    show() {
      document.documentElement.classList.add('is-clipped');
      this.active = true;
    },
    closeForm() {
      VueCookies.set('AgreeTerms', true);
      this.active = false;
      document.documentElement.classList.remove('is-clipped');
      document.querySelector('.modal-card-body').parentElement.classList.remove('scroll-more');
    },
    redirect(url) {
      window.location.href = url;
    },
    showCancelButton() {
      return this.content.cancelButtonText && this.content.cancelRedirectUrl;
    }
  }
};
</script>
