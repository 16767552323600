<template>
  <div
    class="small-groups-filter is-flex slider-toggle"
    :class="{ disabled: settings.loading }"
  >
    <label
      class="switch inline-center is-relative"
      for="small-groups"
      aria-labelledby="small-groups"
    >
      <input
        id="small-groups"
        v-model="isSmallGroups"
        type="checkbox"
        name="small-groups"
        role="switch"
        @change="setSmallGroupsFlag($event)"
      >
      <div class="slider round" />
    </label>
    <p
      class="inline-center"
      :class="{ filterChecked: filterCheckbox }"
    >
      {{
        settings.filterTripsContent.smallGroupsDisplayText
          ? settings.filterTripsContent.smallGroupsDisplayText
          : 'Small Groups'
      }}
    </p>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  props: {
    settings: {
      type: Object
    }
  },
  data() {
    return {
      isSmallGroups: false,
      filtersClicked: false,
      filterCheckbox: false
    };
  },
  created() {
    this.checkDefaultValue();
    EventBus.$on('reset-small-groups-flag', () => {
      this.isSmallGroups = false;
    });
  },
  methods: {
    setSmallGroupsFlag(e) {
      this.filtersClicked = true;
      this.$emit('set-small-groups-flag', this.isSmallGroups);
      this.$emit('filter-search');
    },
    checkDefaultValue() {
      let smallGroupsChecked = this.settings.defaultSmallGroupsFilter;
      let tagsHasSmallGroups = this.settings.tags === 'SmallGroup';
      this.isSmallGroups = smallGroupsChecked || tagsHasSmallGroups;
    }
  }
};
</script>
