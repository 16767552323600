function applyCmsParameters() {
  let paramsObj = window.Cms.searchAppParams;
  let brandsArray = [];
  let typesArray = [];
  if ('defaultSmallGroupsFilter' in paramsObj) {
    this.$data.settings.defaultSmallGroupsFilter = paramsObj.defaultSmallGroupsFilter;
    if (paramsObj['defaultSmallGroupsFilter']) {
      this.$data.settings.filters.tags = 'tags=SmallGroup';
    }
  }
  if ('defaultSpecialOffersFilter' in paramsObj) {
    this.$data.settings.defaultSpecialOffersFilter = paramsObj.defaultSpecialOffersFilter;
    if (paramsObj['defaultSpecialOffersFilter']) {
      this.$data.settings.filters.tags = 'tags=SpecialOffer';
    }
  }
  if ('parentSearch' in paramsObj) {
    this.$data.settings.parentSearch = paramsObj.parentSearch;
  }
  if ('brands' in paramsObj && Array.isArray(paramsObj.brands)) {
    let splitBrandValues = paramsObj.brands.join().split(',');
    for (let i = 0; i < splitBrandValues.length; i++) {
      // splitting the brand value from URL by case here to form an array, then joining back to a string
      // with spaces in between the words - so InsightVacations becomes Insight Vacations - to match the BrandName from the api
      let brandItem;
      if (splitBrandValues[i].match(/[A-Z][A-Z]+/g)) {
        brandItem = splitBrandValues[i];
        brandsArray.push(brandItem);
      } else {
        brandItem = splitBrandValues[i].match(/[A-Z][a-z]+/g).join(' ');
        brandsArray.push(brandItem);
      }
    }
    this.getSelectedBrands(brandsArray);
  }
  if ('travelTypes' in paramsObj && Array.isArray(paramsObj.travelTypes)) {
    let splitTypeValues = paramsObj.travelTypes.join().split(',');
    for (let i = 0; i < splitTypeValues.length; i++) {
      let typeItem = splitTypeValues[i].match(/[A-Z][a-z]+/g).join(' ');
      typesArray.push(typeItem);
    }
    this.getSelectedTypes(typesArray);
  }
  if ('travelTypes' in paramsObj && Array.isArray(paramsObj.travelTypes)) {
    this.getSelectedTypes(paramsObj.travelTypes);
  }
  if ('resultLimit' in paramsObj && typeof paramsObj.resultLimit === 'number') {
    this.$data.viewLimit = paramsObj.resultLimit;
  }
  if ('filtersLocked' in paramsObj) {
    this.$data.settings.filtersLocked = paramsObj.filtersLocked;
  }
  if ('destinations' in paramsObj && Array.isArray(paramsObj.destinations)) {
    this.getSelectedDestinations(paramsObj.destinations);
  }
  if ('continent' in paramsObj && !Array.isArray(paramsObj.destinations)) {
    this.setRegionSearch(paramsObj.continent);
  }
  if ('brochureCodes' in paramsObj && Array.isArray(paramsObj.brochureCodes)) {
    this.getSelectedBrochureCodes(paramsObj.brochureCodes);
  }
  let priceObj = {};
  if ('maxPrice' in paramsObj) {
    priceObj.maxPrice = paramsObj.maxPrice;
  }
  if ('minPrice' in paramsObj) {
    priceObj.minPrice = paramsObj.minPrice;
  }
  if ('minPrice' && 'maxPrice' in paramsObj) {
    priceObj.minPrice = paramsObj.minPrice;
    priceObj.maxPrice = paramsObj.maxPrice;
  }
  this.getPriceSliderValues(priceObj);

  let durationObj = {};
  if ('maxDuration' in paramsObj) {
    durationObj.maxDuration = paramsObj.maxDuration;
  }

  if ('minDuration' in paramsObj) {
    priceObj.minDuration = paramsObj.minDuration;
  }

  if ('minDuration' && 'maxDuration' in paramsObj) {
    durationObj.minDuration = paramsObj.minDuration;
    durationObj.maxDuration = paramsObj.maxDuration;
  }
  this.getDurationSliderValues(durationObj);

  if ('months' in paramsObj && Array.isArray(paramsObj.months)) {
    this.getSelectedMonths(paramsObj.months);
  }
}
export default applyCmsParameters;
