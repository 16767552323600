function makeSearchFilters() {
  let constructedFilters = [];
  let filterOptions = this.$data.settings.filters;

  Object.keys(filterOptions).forEach(function (key) {
    if (filterOptions[key] && !Array.isArray(filterOptions[key])) {
      constructedFilters.push(filterOptions[key]);
    }
  });
  return constructedFilters;
}
export default makeSearchFilters;
