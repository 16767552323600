import * as searchConstants from '../../search-constants.js';

function setTypeFilterText(valuesArray) {
  if (valuesArray.length < 2) {
    this.$data.settings.filtersText.typesText = valuesArray[0];
  } else {
    this.$data.settings.filtersText.typesText =
      '+' + valuesArray.length + searchConstants.FILTERTEXTTYPES;
  }
}
export default setTypeFilterText;
