<template>
  <div
    :class="{ 'is-active': filterVisibility.showBrandFilter }"
    class="dropdown"
  >
    <div class="dropdown-trigger">
      <a
        class="button is-filter"
        href="#"
        aria-haspopup="true"
        aria-controls="dropdown-brands"
        :disabled="settings.loading"
        :class="{ 'is-filter-active': settings.filtersText.brandsText }"
        @click.prevent="toggleFilter()"
      >
        <span
          v-if="
            !settings.filterTripsContent.vacationBrand.vacationBrandMenuText &&
              !settings.filtersText.brandsText
          "
        >Vacation Brand</span>
        <span
          v-if="
            settings.filterTripsContent.vacationBrand.vacationBrandMenuText &&
              !settings.filtersText.brandsText
          "
        >{{ settings.filterTripsContent.vacationBrand.vacationBrandMenuText }}</span>
        <span v-if="settings.filtersText.brandsText">{{ settings.filtersText.brandsText }}</span>
        <span
          v-if="!settings.filtersLocked"
          class="icon is-small"
        >
          <i
            class="fas fa-chevron-down"
            aria-hidden="true"
          />
          <i
            class="fas fa-chevron-up"
            aria-hidden="true"
          />
        </span>
      </a>
    </div>
    <div
      id="dropdown-brands"
      class="dropdown-menu dropdown-brands"
      role="presentation"
    >
      <div class="dropdown-header">
        <div class="dropdown-header-wrap">
          <div class="dropdown-header-data">
            <h4
              v-if="settings.filterTripsContent.vacationBrand.vacationBrandsDisplayText"
              class="dropdown-title"
            >
              {{ settings.filterTripsContent.vacationBrand.vacationBrandsDisplayText }}
            </h4>
            <h4
              v-if="!settings.filterTripsContent.vacationBrand.vacationBrandsDisplayText"
              class="dropdown-title"
            >
              Vacation Brands
            </h4>
            <a
              :href="settings.filterTripsContent.vacationBrand.learnAboutBrandsDisplayLink"
              aria-label="brandsmore"
              class="brandsmore"
            >{{ settings.filterTripsContent.vacationBrand.learnAboutBrandsDisplayText }}</a>
          </div>
          <div class="dropdown-header-close">
            <button
              class="dropdown-close"
              @click.prevent="setFilterVisibility()"
            >
              <span class="is-sr-only">Close Menu</span>
              <span
                class="dropdown-close__icon delete"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <keep-alive>
          <search-brand-filters
            ref="brandselector"
            :filter-trips-content="settings.filterTripsContent"
            :locked="settings.filtersLocked"
            :brands-val="settings.filtersValues.brandsVal"
          />
        </keep-alive>
      </div>
      <div class="dropdown-footer search-footer">
        <div class="buttonholder search-clear-apply">
          <button
            class="btn-clear"
            href="#"
            @click.prevent="$emit('reset-brand-filters')"
          >
            Clear Selection
          </button>
          <button
            class="btn-apply"
            @click.prevent="$emit('filter-search')"
          >
            Apply Selection
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchBrandFilters from '../../vue-components/search/search-brand-filters.vue';
export default {
  components: {
    searchBrandFilters
  },
  props: {
    settings: {
      type: Object
    },
    filterVisibility: {
      type: Object
    }
  },
  data: function () {
    return {
      initialState: []
    };
  },
  methods: {
    toggleFilter() {
      this.$emit('brand-filter-actions', '#dropdown-brands');
      if (this.filterVisibility.showBrandFilter) {
        this.initialState = JSON.parse(JSON.stringify(this.settings.filtersValues.brandsVal));
      } else {
        this.initialState = [];
      }
    },
    setFilterVisibility: function (target) {
      this.$emit('set-filter-visibility', target);
    },
    resetInitialState() {
      if (!this.initialState) {
        this.initialState = [];
      }
      this.$emit('get-selected-brands', this.initialState);
      this.$emit('set-filter-visibility');
    }
  }
};
</script>
