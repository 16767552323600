function setSmallGroupsFlag(flagState) {
  if (flagState) {
    if (this.$data.settings.filters.tags.indexOf('SmallGroup') === -1) {
      this.$data.settings.filters.tags =
        this.$data.settings.filters.tags.indexOf('tags=') >= 0
          ? this.$data.settings.filters.tags + ' SmallGroup'
          : 'tags=SmallGroup';
      this.$data.settings.filters.tags = this.$data.settings.filters.tags.trim();
    }
  } else {
    this.$data.settings.filters.tags = this.$data.settings.filters.tags
      .replace('SmallGroup', '')
      .trim();
    if (this.$data.settings.filters.tags === 'tags=') {
      this.$data.settings.filters.tags = '';
    }
  }
}
export default setSmallGroupsFlag;
