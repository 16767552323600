function stickyNav() {
  let fixedClass = 'is-fixed';
  let searchPage = document.querySelector('.page-vacationSearch');
  let footer = document.querySelector('.footer');

  if (searchPage) {
    let headerHeight = searchbar?.offsetHeight || 0;
    let top;

    window.addEventListener('load', function () {
      footer.style.marginTop = '10rem';
    });

    window.addEventListener('scroll', function () {
      let searchbar = document.getElementById('searchbarholder');
      let headerHeight = searchbar?.offsetHeight ?? 0;

      top = window.scrollY;
      if (top > headerHeight) {
        searchbar.classList.add(fixedClass);
      } else {
        searchbar.classList.remove(fixedClass);
      }
    });
  }
}
export default stickyNav;
