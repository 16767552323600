import * as searchConstants from '../../search-constants.js';

function getSelectedBrands(selectedBrandsArray) {
  this.$data.settings.filtersValues.brandsVal = selectedBrandsArray;
  this.setBrandFilterText(selectedBrandsArray);
  // receives selected brands array from brands filters and converts to string
  let brandsString = selectedBrandsArray.join();
  // im also removing the space here with the replace method and regexp so that search can understand the terms
  brandsString = brandsString.replace(/\s/g, '');
  // pass the string to the data for use in search
  this.$data.settings.filters.brandsSelected = searchConstants.BRANDSSELECTEDPREFIX + brandsString;
}
export default getSelectedBrands;
