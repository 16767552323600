var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:{ 'is-active': _vm.filterVisibility.showTypeFilter }},[_c('div',{staticClass:"dropdown-trigger"},[_c('a',{staticClass:"button is-filter",class:{ 'is-filter-active': _vm.settings.filtersText.typesText },attrs:{"href":"#","aria-haspopup":"true","aria-controls":"dropdown-types","disabled":_vm.settings.loading},on:{"click":function($event){$event.preventDefault();return _vm.toggleFilter()}}},[(
          !_vm.settings.filterTripsContent.travelType.travelTypeMenuText &&
            !_vm.settings.filtersText.typesText
        )?_c('span',[_vm._v("Travel Type")]):_vm._e(),_vm._v(" "),(
          _vm.settings.filterTripsContent.vacationBrand.vacationBrandMenuText &&
            !_vm.settings.filtersText.typesText
        )?_c('span',[_vm._v(_vm._s(_vm.settings.filterTripsContent.travelType.travelTypeMenuText))]):_vm._e(),_vm._v(" "),(_vm.settings.filtersText.typesText)?_c('span',[_vm._v(_vm._s(_vm.settings.filtersText.typesText))]):_vm._e(),_vm._v(" "),(!_vm.settings.filtersLocked)?_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-chevron-down",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('i',{staticClass:"fas fa-chevron-up",attrs:{"aria-hidden":"true"}})]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-types dropdown-brands",attrs:{"id":"dropdown-types","role":"presentation"}},[_c('div',{staticClass:"dropdown-header"},[_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('h4',{staticClass:"dropdown-title",domProps:{"textContent":_vm._s(
              _vm.settings.filterTripsContent.travelType.travelTypeDisplayText
                ? _vm.settings.filterTripsContent.travelType.travelTypeDisplayText
                : 'Travel Types'
            )}})]),_vm._v(" "),_c('div',{staticClass:"level-right"},[_c('button',{staticClass:"dropdown-close",on:{"click":function($event){$event.preventDefault();return _vm.resetInitialState()}}},[_c('span',{staticClass:"is-sr-only"},[_vm._v("Close Menu")]),_vm._v(" "),_c('span',{staticClass:"dropdown-close__icon delete",attrs:{"aria-hidden":"true"}})])])]),_vm._v(" "),_c('keep-alive',[_c('search-type-filters',{ref:"typeselector",attrs:{"filter-trips-content":_vm.settings.filterTripsContent,"locked":_vm.settings.filtersLocked,"types-val":_vm.settings.filtersValues.typesVal}})],1),_vm._v(" "),_c('a',{staticClass:"brandsmore",attrs:{"href":_vm.settings.filterTripsContent.travelType.learnAboutTravelTypesDisplayLink,"aria-label":"brandsmore"}},[_vm._v(_vm._s(_vm.settings.filterTripsContent.travelType.learnAboutTravelTypesDisplayText))])],1),_vm._v(" "),_c('div',{staticClass:"dropdown-footer"},[_c('div',{staticClass:"buttonholder level is-mobile"},[_c('button',{staticClass:"button is-subtle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('reset-type-filters')}}},[_vm._v("\n          Clear\n        ")]),_vm._v(" "),_c('button',{staticClass:"button is-primary",on:{"click":function($event){$event.preventDefault();return _vm.$emit('filter-search')}}},[_vm._v("\n          Apply\n        ")])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }