import * as searchConstants from '../../search-constants.js';

function getSelectedDestinations(destinationsArray) {
  if (destinationsArray.length > 0 && !this.$data.currentBrochureCodes) {
    this.$data.settings.currentSearchPrefix = searchConstants.DEFAULTSEARCHTERM;
  }
  this.setDestinationFilterText(destinationsArray);
  this.$data.settings.currentDestinations = destinationsArray.join();
}
export default getSelectedDestinations;
