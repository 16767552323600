<template>
  <div class="map-expand-button">
    <button
      v-if="displayCloseModalButton"
      aria-label="close"
      class="modal-button"
      @click.prevent="closeForm"
    >
      <i class="fa fa-times" />
    </button>
    <button class="modal-button modal-button-plus" @click="zoomIn">+</button>
    <button class="modal-button modal-button-minus" @click="zoomOut">-</button>
    <button
      class="modal-button level expand"
      aria-label="map Expand"
      @click.prevent="toggleModal()"
    >
      <span class="icon expand-btn">
        <i class="fas fa-expand" />
      </span>
    </button>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  props: ['displayCloseModalButton'],
  data() {
    return {
      scale: 1,
      active: false
    };
  },
  methods: {
    toggleModal() {
      this.scale = 1;
      this.$emit('scale-changed', this.scale);
      EventBus.$emit('TOGGLE_MAP_MODAL');
    },
    zoomIn() {
      if (this.scale <= 3) {
        this.scale += 1;
        this.$emit('scale-changed', this.scale);
      }
    },
    zoomOut() {
      this.scale = Math.max(1, this.scale - 1);
      this.$emit('scale-changed', this.scale);
    },
    closeForm() {
      document.documentElement.classList.remove('is-clipped');
      this.active = false;
      this.scale = 1;
      this.$emit('scale-changed', this.scale);
      EventBus.$emit('close-form-state-active', this.active);
    }
  }
};
</script>
