<template>
  <section class="generic-banner subsection container">
    <div
      v-if="bannerdata?.showBanner"
      class="has-text-centered section"
      :class="[
        bannerdata?.brandLogoHasValue && !bannerdata?.bannerBackgroundColour
          ? `${bannerdata?.brandUrlSegment}`
          : 'partner-banner'
      ]"
      :style="{
        backgroundColor: '#' + bannerdata?.bannerBackgroundColour
      }"
    >
      <h2
        v-if="bannerdata?.bannerHeaderText"
        class="hero-marketing-banner__header"
        :style="{ color: '#' + bannerdata?.bannerTextColour }"
      >
        {{ bannerdata?.bannerHeaderText }}
      </h2>
      <p
        v-if="bannerdata?.bannerSubHeaderText"
        class="hero-marketing-banner__subheader has-text-weight-normal"
        :style="{ color: '#' + bannerdata?.bannerTextColour }"
      >
        {{ bannerdata?.bannerSubHeaderText }}
      </p>
    </div>
  </section>
</template>
<script>
export default {
  props: ['bannerdata'],
  data() {
    return {};
  }
};
</script>
