//function to manage the URL state when a user interacts with the filters
// @param - paramString  - a string of the search parameters as chosen by the user will be passed to the function
// expected output will be that the URL is updated when the function is called

function manageUrlState(paramString) {
  let parentUrl = window.location.href.split('?')[0];
  let fullUrl = parentUrl + '?' + paramString;
  history.pushState(null, null, fullUrl);
}
export default manageUrlState;
