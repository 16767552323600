<template>
  <section class="section hero-container">
    <div
      class="hero-brand"
      :class="!herobranddata.heroWidth ? 'hero-full' : 'container'"
    >
      <div
        class="hero-overlay"
        :style="{ opacity: opacityValue + '%' }"
      />
      <div
        class="hero-brand-wrap"
        :class="!herobranddata.heroWidth ? 'container' : ''"
      >
        <div
          v-if="herobranddata?.onlyBrandValue && !herobranddata.hideBrandLogo"
          class="hero-brand-logo"
        >
          <img
            class="logo-image"
            :src="herobranddata?.brandLogoUrl"
            :alt="herobranddata?.brandLogoAltText"
          >
        </div>
        <div
          v-if="!herobranddata?.onlyBrandValue"
          class="hero-brand-data"
        >
          <h2
            v-if="herobranddata?.heroTitle"
            class="hero-brand-title"
          >
            {{ herobranddata?.heroTitle }}
          </h2>

          <p
            v-if="herobranddata?.subtitle"
            class="hero-brand-subtitle"
          >
            {{ herobranddata?.subtitle }}
          </p>

          <a
            v-if="herobranddata?.ctaButton"
            href="#searchbar"
            class="button cta ctaButton"
          >{{
            herobranddata?.ctaButton
          }}</a>
        </div>
      </div>

      <img
        v-if="herobranddata?.marketingBackgroundImageUrl"
        class="is-background"
        :src="herobranddata?.marketingBackgroundImageUrl"
        :alt="herobranddata?.marketingBackgroundImageAltText"
      >

      <!-- <hero-banner
				v-if="herobranddata.showBanner"
				:herobranddata="herobranddata"
				id="hero-banner"></hero-banner> -->
    </div>
  </section>
</template>
<script>
//import HeroBanner from "../../vue-components/heros/hero-banner.vue";
export default {
  name: 'HeroBrand',
  props: ['herobranddata'],
  data() {
    return {
      opacityValue: 50
    };
  },
  mounted() {
    this.opacityValue =
      this.herobranddata.opacityHeroValue != ''
        ? this.herobranddata.opacityHeroValue
        : this.opacityValue;
  },
  // components: {
  // 	"hero-banner": HeroBanner,
  // },
  methods: {
    getHeroHeight() {
      let heroBrandHeight = document.querySelectorAll('.hero-brand')[0].offsetHeight;
      let heroBannerHeight = document.querySelector('#hero-banner').offsetHeight;
      document.querySelector('#hero-brand-section').style.height =
        16 + heroBrandHeight + heroBannerHeight + 'px';
    }
  }
};
</script>
