<template>
  <div class="dropdown">
    <div class="dropdown-trigger">
      <button
        class="button"
        :class="{
          'filters-active': selectedship && selectedship !== 'all-ships',
          'no-dropdown': ships.length === 1 || shipsmatch
        }"
        :disabled="apierror"
      >
        <span v-if="!ships.length && !apierror"><i class="fas fa-lg fa-spin fa-circle-notch" /></span>
        <span v-if="ships.length > 1 && selectedShip === 'all-ships' && !shipsmatch">Filter by Ship</span>
        <span v-if="selectedShip !== 'all-ships' && !shipsmatch">{{ selectedShip }}</span>
        <span
          v-if="ships.length === 1"
          v-text="ships[0]"
        />
        <span
          v-if="shipsmatch"
          :class="{ 'is-size-7': ships.length > 1 }"
          v-text="ships.join().replace(/[\,]/g, ', ')"
        />
        <span
          v-if="ships.length > 1 && !shipsmatch"
          class="icon is-small"
        >
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
          />
        </span>
      </button>
    </div>
    <ul
      v-if="ships.length > 1 && !shipsmatch"
      class="dropdown-menu dropdown-content"
    >
      <li
        class="dropdown-item is-paddingless is-flex"
        :class="selectedShip === 'all-ships' ? 'selected' : ''"
      >
        <label
          class="dropdown-label"
          for="all-ships"
        >
          <input
            id="all-ships"
            v-model="selectedShip"
            type="radio"
            value="all-ships"
            @change="updateSelectedShip(selectedShip)"
          >
          All Ships
        </label>
      </li>
      <li
        v-for="ship in ships"
        :key="ship.index"
        class="dropdown-item is-paddingless is-flex"
        :class="ship === selectedShip ? 'selected' : ''"
      >
        <label
          class="dropdown-label"
          :for="ship.index"
        >
          <input
            :id="ship.index"
            v-model="selectedShip"
            type="radio"
            :value="ship"
            @change="updateSelectedShip(selectedShip)"
          >
          {{ ship }}
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['model', 'ships', 'selectedship', 'shipsmatch', 'apierror'],
  data() {
    return {
      selectedShip: 'all-ships'
    };
  },
  methods: {
    updateSelectedShip() {
      this.$emit('update-selected-ship', this.selectedShip);
    }
  }
};
</script>
