import Vue from 'vue';
import HeroBrand from '../../vue-components/heros/hero-brand.vue';

function heroBrandView() {
  new Vue({
    el: '#hero-brand-section',
    components: {
      'hero-brand': HeroBrand
    },
    data() {
      return {
        herobranddata: window.Cms.heroModel
      };
    }
  });
}
export default heroBrandView;
