import Vue from 'vue';
import Banner from '../vue-components/generic/generic-banner.vue';

function BrandView() {
  new Vue({
    el: '#generic-banner',
    components: {
      'generic-banner': Banner
    },
    data() {
      return {
        bannerdata: window.Cms.bannerModel
      };
    }
  });
}
export default BrandView;
