import 'nodelist-foreach-polyfill';
function mainNavHelperDesktop() {
  let navParent = document.querySelector('.navbar');

  if (!navParent) {
    return;
  }

  const navParents = document.querySelectorAll('.navbar-item.has-dropdown');
  const navBackground = document.querySelector('.dropdown-background');
  const activeClass = 'is-active';
  const triggerEnterClass = 'trigger-enter';
  const navbarContainer = document.querySelector('.navbar');

  function handleEnter() {
    this.classList.add(triggerEnterClass);

    setTimeout(() => {
      if (this.classList.contains(triggerEnterClass)) {
        this.classList.add(activeClass);
      }
    }, 100);

    navBackground.classList.add('open');
    const dropdown = this.querySelector('.navbar-dropdown');
    const dropdownCoords = dropdown.getBoundingClientRect();
    const navbarContainerCoords = navbarContainer.getBoundingClientRect();

    const coords = {
      width: dropdownCoords.width,
      height: dropdownCoords.height,
      top: dropdownCoords.top - navbarContainerCoords.top,
      left: dropdownCoords.left - navbarContainerCoords.left
    };

    navBackground.style.setProperty('width', `${coords.width}px`);
    navBackground.style.setProperty('height', `${coords.height}px`);
    navBackground.style.setProperty('top', `${coords.top}px`);
    navBackground.style.setProperty('left', `${coords.left}px`);
  }

  function handleLeave() {
    if (navBackground.getAttribute('resizing') === 'yes') {
      navBackground.setAttribute('resizing', 'no');
      return;
    }

    this.classList.remove(triggerEnterClass);
    this.classList.remove(activeClass);
    navBackground.classList.remove('open');
  }
  function handleLeaveTab(parent) {
    parent.classList.remove(triggerEnterClass);
    parent.classList.remove(activeClass);
    navBackground.classList.remove('open');
  }

  navParents.forEach((parent) => parent.addEventListener('mouseenter', handleEnter));
  navParents.forEach((parent) => parent.addEventListener('mouseleave', handleLeave));
  navParents.forEach((parent) => parent.addEventListener('focusin', handleEnter));
  navParents.forEach((parent) =>
    parent.addEventListener('keydown', (e) => {
      if ((e.shiftKey && e.keyCode === 9) || e.keyCode === 27) {
        handleLeaveTab(parent);
      }
    })
  );
  navParents.forEach((parent) => {
    let linksList = parent.querySelectorAll('.is-visible a');
    let linksArray = Array.from(linksList);
    linksArray.forEach((link) => {
      if (linksArray[0].classList.contains('is-hidden')) {
        linksArray.shift();
      }
      link.classList.add('access-link');
    });
    linksArray[linksArray.length - 1].addEventListener('focusout', () => {
      handleLeaveTab(parent);
    });
  });
}

export default mainNavHelperDesktop;
