function destinationMainMenu() {
  let hasCountriesItems = document.querySelectorAll('.hasCountries');
  let contentCountries = document.querySelectorAll('.navbar-content_countries');
  let windowWidth = window.innerWidth;
  let mobileBackBtns = document.querySelectorAll('.country-go-back');
  let navBackground = document.querySelector('.dropdown-background');

  window.onload = function () {
    contentCountries.forEach((item) => item.addEventListener('mouseleave', handleCountriesLeave));
  };

  const handleClick = (e) => {
    e.stopPropagation();
    let targetItem = e.target;
    hasCountriesItems.forEach((item) => item.classList.remove('selected'));
    contentCountries.forEach((item) => item.classList.remove('open')); // TO-DO: Review close when not clicking show all btn

    if (targetItem.classList.contains('hasCountries-item')) {
      targetItem.classList.toggle('selected');
      targetItem.nextElementSibling.classList.toggle('open'); // TO-DO: Review close when click showmore/less
    }
  };

  const handleClickMobile = (e) => {
    e.preventDefault();
    e.target.nextElementSibling.classList.add('open');
  };

  const countryGoBack = (e) => {
    e.preventDefault();
    mobileBackBtns.forEach((item) => item.parentNode.parentNode.classList.remove('open'));
  };

  const handleCountriesLeave = (e) => {
    if (!e.relatedTarget.classList.contains('dropdown-background')) {
      hasCountriesItems.forEach((item) => item.classList.remove('selected'));
      contentCountries.forEach((item) => item.classList.remove('open'));
    }
    e.stopPropagation();
  };

  if (windowWidth <= 1024) {
    hasCountriesItems.forEach((item) =>
      item.querySelector('.navbar-item').addEventListener('click', handleClickMobile)
    );
    mobileBackBtns.forEach((item) => item.addEventListener('click', countryGoBack));
  } else {
    hasCountriesItems.forEach((item) =>
      item.closest('.navbar-item').addEventListener('click', handleClick, false)
    );
    document
      .getElementById('destination-menu')
      .addEventListener('mouseleave', handleCountriesLeave);
  }

  // SHOW MORE FUNCTIONALITY
  let listOfCountries = document.querySelectorAll('.navbar-country-item');
  let showMoreBtn = document.getElementById('show-more-countries');
  let showLessBtn = document.getElementById('show-less-countries');

  const toggleButtonVisibility = (showBtn, hideBtn) => {
    showBtn.style.display = 'block';
    hideBtn.style.display = 'none';
  };

  const showMoreCountries = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navBackground.setAttribute('resizing', 'yes');
    listOfCountries.forEach((item) => {
      item.classList.contains('not-top-country') ? item.classList.add('showCountry') : '';
    });
    toggleButtonVisibility(showLessBtn, showMoreBtn);
  };
  const showLessCountries = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navBackground.setAttribute('resizing', 'yes');
    listOfCountries.forEach((item) => {
      if (item.classList.contains('not-top-country')) item.classList.remove('showCountry');
    });
    toggleButtonVisibility(showMoreBtn, showLessBtn);
  };
  showMoreBtn ? showMoreBtn.addEventListener('click', showMoreCountries) : undefined;
  showLessBtn ? showLessBtn.addEventListener('click', showLessCountries) : undefined;
}

export default destinationMainMenu;
