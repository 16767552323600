<template>
  <div id="mapmodal" class="modal modal-geq modal-map" :class="{ 'is-active': active }">
    <div class="modal-background" @click.prevent="closeForm" />
    <div
      v-if="!mapTypeImage"
      class="modal-card modal-map modal-map-image"
      :style="{ backgroundImage: 'url(' + mapurl + ')' }"
    />

    <map-image-zoom
      v-if="mapTypeImage"
      :display-close-modal-button="true"
      :display-fullscreen-button="false"
      :image-src="mapurl"
      :image-default-class="imageDefaultClass"
      :image-additional-class="imageAdditionalClass"
      :image-alt="itinerarymodel?.mapImageAltTag"
    />
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
import { closeModal } from '../../modals/closeModal';
import MapImageZoom from '../../vue-components/map/map-image-zoom.vue';

export default {
  components: {
    'map-image-zoom': MapImageZoom
  },
  props: ['mapurl', 'mapTypeImage', 'itinerarymodel', 'tourtype'],
  data() {
    return {
      active: false,
      imageDefaultClass: 'fullimage'
    };
  },
  computed: {
    imageAdditionalClass() {
      return this.tourtype.isV4Tour ? 'v4map' : this.tourtype.isC1Tour ? 'c1Map' : '';
    }
  },
  created: function () {
    EventBus.$on('TOGGLE_MAP_MODAL', () => {
      document.documentElement.classList.toggle('is-clipped');
      this.show();
    });
    EventBus.$on('close-form-state-active', (newActive) => {
      this.active = newActive;
    });
  },
  mounted() {
    this.closeModal(this.closeForm);
  },
  methods: {
    show() {
      this.active = !this.active;
    },
    closeForm() {
      document.documentElement.classList.remove('is-clipped');
      this.active = false;
    },
    closeModal
  }
};
</script>
