<template>
  <section
    v-if="featuredsection"
    class="featured-content"
  >
    <div class="columns">
      <div
        class="column featured-content__desc"
        :class="
          featuredsection.hideIntroductionMedia ? 'is-full-desktop' : 'is-6-tablet is-5-desktop'
        "
      >
        <div
          class="featured-content__text"
          v-html="featuredsection.introText"
        />
      </div>
      <div
        v-if="!featuredsection.hideIntroductionMedia"
        class="column is-6-tablet is-7-desktop featured-content__media"
      >
        <div
          v-if="featuredsection.introImageUrl && featuredsection.introVideoUrl === ''"
          class="featured-content__media-image"
          :style="{
            backgroundImage: 'url(\'' + featuredsection.introImageUrl + '\')'
          }"
        />
        <div
          v-if="featuredsection.introVideoUrl"
          class="featured-content__media-video"
        >
          <embed
            :title="featuredsection.brandName"
            :src="featuredsection.introVideoUrl"
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FeaturedSection',
  props: ['featuredsection']
};
</script>
