<template>
  <div>
    <div
      v-if="raqmodel.tour.isUniworldTour"
      class="container"
    >
      <h3 class="section-header">
        {{ dictionarymodel.whatsIncluded }}
      </h3>
      <div class="highlights is-relative">
        <div>
          <div class="included-column">
            <ul
              v-for="({ type, highlitghts }, index) in includedmodel.whatsIncludedCol1"
              :key="index"
            >
              <li
                v-if="highlitghts[0].imageUrl"
                class="included-image-container"
              >
                <p class="imageurl included-image">
                  <img
                    :src="highlitghts[0].imageUrl"
                    alt="What's Included image"
                  >
                </p>
              </li>
              <li v-if="type">
                <p class="subtitle">
                  <b>{{ type }}</b>
                </p>
              </li>
              <li
                v-for="({ title, text }, ind) in highlitghts"
                :key="ind"
                class="included-list-item"
                v-html="text"
              />
            </ul>
            <ul
              v-for="({ type, highlitghts }, index) in includedmodel.whatsIncludedCol2"
              :key="index"
            >
              <li
                v-if="highlitghts[0].imageUrl"
                class="included-image-container"
              >
                <p class="imageurl included-image">
                  <img
                    :src="highlitghts[0].imageUrl"
                    alt="What's Included image"
                  >
                </p>
              </li>
              <li v-if="type">
                <p class="subtitle">
                  <b>{{ type }}</b>
                </p>
              </li>
              <li
                v-for="({ text }, ind) in highlitghts"
                :key="ind"
                class="included-list-item"
                v-html="text"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!raqmodel.tour.isUniworldTour">
      <div class="included__wrapper">
        <h3 class="included__wrapper-title">
          {{ dictionarymodel.whatsIncluded }}
        </h3>
        <div
          class="included__box"
          :class="{
            columnOne:
              includedmodel.whatsIncludedCol1.length === 0 ||
              includedmodel.whatsIncludedCol2.length === 0
          }"
        >
          <div
            v-if="includedmodel.whatsIncludedCol1.length > 0"
            class="included__box-column"
          >
            <div v-for="{ highlitghts } in includedmodel.whatsIncludedCol1">
              <ul>
                <li v-for="{ title, text } in highlitghts">
                  <i
                    v-if="text"
                    class="fas fa-check"
                    aria-hidden="true"
                  />
                  <h4
                    v-if="title"
                    class="subtitle"
                  >
                    {{ title }}
                  </h4>
                  <div v-html="text" />
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="includedmodel.whatsIncludedCol2.length > 0"
            class="included__box-column"
          >
            <div v-for="{ highlitghts } in includedmodel.whatsIncludedCol2">
              <ul>
                <li v-for="{ title, text } in highlitghts">
                  <i
                    v-if="text"
                    class="fas fa-check"
                    aria-hidden="true"
                  />
                  <h4
                    v-if="title"
                    class="subtitle"
                  >
                    {{ title }}
                  </h4>
                  <div v-html="text" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['includedmodel', 'dictionarymodel', 'raqmodel'],
  data() {
    return {};
  },
  methods: {}
};
</script>
