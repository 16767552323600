import eventBus from '../../vue-functions/event-bus.js';

function resetPriceSlider() {
  eventBus.$emit('price-slider-reset');

  this.settings.filters.minPrice = false;
  this.settings.filters.maxPrice = false;
  this.settings.filtersText.priceText = false;
}
export default resetPriceSlider;
