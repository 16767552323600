function mainNavHelperMobile() {
  let navParent = document.querySelector('.navbar');

  if (!navParent) {
    return;
  }

  let navParents = document.querySelectorAll('.navbar-item.has-dropdown');
  let navWrapper = document.querySelector('.navbar-dropdown');
  let activeClass = 'is-active';

  function toggleNavVisiblity(elementClicked) {
    for (let i = 0; i < navParents.length; i++) {
      let btnTrigger = navParents[i].querySelector('.navbar-parent');
      let currParent = navParents[i];

      if (elementClicked === btnTrigger) {
        currParent.classList.toggle(activeClass);
      } else {
        currParent.classList.remove(activeClass);
      }
    }
  }
  function closeAllNavs() {
    toggleNavVisiblity(null);
  }

  for (let i = 0; i < navParents.length; i++) {
    let currParent = navParents[i];
    let btnTrigger = currParent.querySelector('.navbar-parent');

    btnTrigger.addEventListener('click', function (e) {
      e.stopPropagation();
      toggleNavVisiblity(this);
    });
  }

  let mainNavClickOutside = function (event) {
    let isClickInside = navWrapper.contains(event.target);

    if (!isClickInside) {
      closeAllNavs();
    }
  };

  document.addEventListener('click', mainNavClickOutside);

  document.addEventListener('focusin', function (event) {
    let target = event.target;
    let isFocusInside = navWrapper.contains(target);
    if (!isFocusInside) {
      closeAllNavs();
    }
  });
}
export default mainNavHelperMobile;
