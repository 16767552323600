<template>
  <section
    class="section hero-container"
    :class="!herodata.heroWidth ? 'hero-full' : 'container'"
  >
    <div
      class="hero-overlay"
      :style="{ opacity: opacityValue + '%' }"
    />
    <div
      class="hero-container__title"
      :class="!herodata.heroWidth ? 'container' : ''"
    >
      <h1 class="headline-font">
        {{ herodata.title }}
      </h1>
    </div>
    <img
      v-if="herodata.imageUrl"
      :src="herodata.imageUrl"
      class="is-background"
      :alt="herodata.altText"
    >
  </section>
</template>
<script>
export default {
  name: 'HeroGeneric',
  props: ['herodata'],
  data() {
    return {
      opacityValue: 50
    };
  },
  mounted() {
    this.opacityValue =
      this.herodata.opacityHeroValue !== '' ? this.herodata.opacityHeroValue : this.opacityValue;
  }
};
</script>
