<template>
  <section
    v-if="
      includedmodel?.whatsIncludedCol1?.length > 0 || includedmodel?.whatsIncludedCol2?.length > 0
    "
    id="included-section"
    class="included section"
  >
    <div class="included__wrapper">
      <h3 class="included__wrapper-title">
        {{ dictionarymodel?.whatsIncluded }}
      </h3>
      <div
        class="included__box"
        :class="{
          columnOne:
            includedmodel?.whatsIncludedCol1?.length === 0 ||
            includedmodel?.whatsIncludedCol2?.length === 0
        }"
      >
        <div
          v-if="includedmodel?.whatsIncludedCol1?.length > 0"
          class="included__box-column"
        >
          <div v-for="{ type, highlitghts } in includedmodel?.whatsIncludedCol1">
            <h4
              v-if="type"
              class="subtitle"
            >
              {{ type }}
            </h4>
            <ul>
              <li v-for="{ text } in highlitghts">
                <i
                  v-if="text"
                  class="fas fa-check"
                  aria-hidden="true"
                />
                <div v-html="text" />
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="includedmodel?.whatsIncludedCol2?.length > 0"
          class="included__box-column"
        >
          <div v-for="{ type, highlitghts } in includedmodel?.whatsIncludedCol2">
            <h4
              v-if="type"
              class="subtitle"
            >
              {{ type }}
            </h4>
            <ul>
              <li v-for="{ text } in highlitghts">
                <i
                  v-if="text"
                  class="fas fa-check"
                  aria-hidden="true"
                />
                <div v-html="text" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['includedmodel', 'dictionarymodel', 'raqmodel'],
  methods: {}
};
</script>
