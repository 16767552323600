<template>
  <div>
    <button
      class="button is-secondary cta"
      :title="!booknowurl ? 'Book Now unavailable' : ''"
      :disabled="!booknowurl"
      href="#"
      @click.prevent="book(booknowurl)"
    >
      Book Now
    </button>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  props: {
    booknowurl: String
  },
  methods: {
    book(booknowurl) {
      this.dataLayerBookNowClicked();
      axios
        .post(booknowurl, null, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function (response) {
          window.location = response.data.url;
        })
        .catch(function () {});
    },
    dataLayerBookNowClicked() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'Book Now Clicked' });
    }
  }
};
</script>
