import Vue from 'vue';
import contentModulesCard from '../vue-components/content-modules/content-modules-card.vue';

function contentModulesInfoCard() {
  new Vue({
    el: '#content-modules-card',
    components: {
      'content-modules-card': contentModulesCard
    },
    data() {
      return {
        modulescard: window.Cms.modulescard
      };
    }
  });
}
export default contentModulesInfoCard;
