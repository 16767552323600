<template>
  <div
    v-if="hasCountries && countryCount > 0"
    class="searchlisting-tour-data-item"
  >
    <i class="fas fa-flag" />
    <span class="searchlisting__item">
      {{ result.countriesVisited.split(',').slice(0, 2).join(',') }}
      <a
        v-if="countryCount > 2"
        :href="`${result.url}/#overview-section`"
        target="_blank"
      >... +{{ countryCount - 2 }} more</a>
    </span>
  </div>
</template>
<script>
import * as searchConstants from '../../search-constants.js';
export default {
  filters: {
    trimString: function (string) {
      return string.trim();
    }
  },
  props: ['settings', 'result', 'countryCount'],
  data: function () {
    return {
      shownCountries: [],
      shownCountriesLimit: searchConstants.COUNTRIESDISPLAYLIMIT,
      hasCountries: false,
      countriesLength: 0,
      totalCountries: 0
    };
  },
  mounted() {
    this.updateResult();
  },
  methods: {
    updateResult() {
      let countryList = this.result.countriesVisited;
      if (typeof countryList !== 'undefined' && countryList !== null) {
        this.hasCountries = true;
        let countryArray = countryList.split(',');
        this.totalCountries = countryArray.length;
        this.countriesLength = this.totalCountries - this.shownCountriesLimit;
        for (let i = 0; i < this.shownCountriesLimit; i++) {
          if (countryArray[i]) {
            this.shownCountries.push(countryArray[i]);
          }
        }
      }
    }
  }
};
</script>
