import './scss/brands/expediacruises/expediacruises-index.scss';
import 'materialize-css';
import Vue from 'vue';
import VueMq from 'vue-mq';

import mobileMenu from './assets/js/navigation/mobile-menu';
import mainNavHelper from './assets/js/navigation/main-nav-helper';
import autoCompleteSearch from './assets/js/search/search-auto-complete';
import stickyNav from './assets/js/navigation/sticky-nav';
import stickyMap from './assets/js/map/sticky-map';
import backToTop from './assets/js/ui-functions/back-to-top';
import scrollTo from './assets/js/ui-functions/scroll-to';
import fixIEImages from './assets/js/ie-fixes/fix-ie-images';
import featureExpand from './assets/js/feature/feature-expand';
import brandPods from './assets/js/feature/brand-pods';
import materializeFunctions from './assets/js/ui-functions/materializeFunctions';
import smoothScroll from './assets/js/ui-functions/smoothScroll';
import featureSection from './assets/js/feature/feature-section';
import accordion from './assets/js/ui-functions/accordion';
import searchFilterToggle from './assets/js/search/search-filter-toggle';
import search from './assets/js/vue-apps/app-search';
import validateModal from './assets/js/validation/validateMembership';
import dropdownFunction from './assets/js/common/dropdown';
import subSearch from './assets/js/search/sub-search';
import websiteFtcModals from './assets/js/vue-apps/app-ftc-modals';
import websiteDeals from './assets/js/vue-apps/app-deals';
import websiteOngoingDeals from './assets/js/vue-apps/app-ongoing-deals';
import websiteMapModal from './assets/js/vue-apps/app-map-modal';
import embeddedSearch from './assets/js/vue-apps/app-embedded-search';
import bookingWidget from './assets/js/booking-widget/bookingWidget';
import siteModal from './assets/js/vue-apps/app-site-modal';
import heroView from './assets/js/vue-apps/app-hero';
import tourMenuView from './assets/js/vue-apps/app-tour-menu';
import overviewView from './assets/js/vue-apps/app-overview';
import availabilityList from './assets/js/vue-apps/app-availability';
import itineraryView from './assets/js/vue-apps/app-itinerary';
import shipsView from './assets/js/vue-apps/app-ships';
import includedView from './assets/js/vue-apps/app-included';
import itineraryModal from './assets/js/modals/itineraryModal';
import secundaryMenu from './assets/js/vue-apps/app-secundary-menu';
import contentModules from './assets/js/vue-apps/app-content-modules';
import subnavControl from './assets/js/navigation/subnav-control';
import getTourPage from './assets/js/vue-apps/app-tour';
import featureBrands from './assets/js/vue-apps/app-featured-brands';
import featuredIntroSectionView from './assets/js/vue-apps/app-featured-intro-section';
import contentModulesInfoCard from './assets/js/vue-apps/app-content-modules-card';
import brandLogoImage from './assets/js//logos/logo-size';
import heroGenericView from './assets/js/vue-apps/heros/app-hero-generic';
import heroBrandView from './assets/js/vue-apps/heros/app-hero-brand';
import BrandView from './assets/js/vue-apps/app-generic-banner';
import heroCarouselView from './assets/js/vue-apps/heros/app-hero-carousel';
import heroEmbeddedSearchView from './assets/js/vue-apps/heros/app-hero-embeddedSearch';
import featureDestinations from './assets/js/vue-apps/app-featured-destinations';
import featuredParentSectionView from './assets/js/vue-apps/app-featured-parent-section';

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 769,
    laptop: 1025,
    desktop: 1281
  }
});

let currentUrl = window.location.hostname;
if (currentUrl.includes('leisure')) {
  validateModal();
}

let searchEl = document.querySelector('#app-search');
if (searchEl && window.Cms.searchPage) {
  search();
  searchFilterToggle();
}

let dropdownEl = document.querySelector('.dropdown-trigger');
if (dropdownEl) {
  dropdownFunction();
}

let subSearchEl = document.querySelector('#website-sub-search');
if (subSearchEl) {
  subSearch();
}

let ftcModalEl = document.querySelector('#app-ftcModals');
if (ftcModalEl) {
  websiteFtcModals();
}

let dealsEl = document.querySelector('#app-deals');
if (dealsEl) {
  websiteDeals();
}

let ongoingDealsEl = document.querySelector('#app-ongoing-deals');
if (ongoingDealsEl) {
  websiteOngoingDeals();
}

let expandMapEl = document.querySelector('#app-mapModal');
if (expandMapEl) {
  websiteMapModal();
}

let embeddedSearchEl = document.querySelector('#app-embeddedSearch');
if (embeddedSearchEl) {
  embeddedSearch();
}

let siteModalEl = document.querySelector('#app-site-modal');
if (siteModalEl) {
  siteModal();
}

let heroEl = document.querySelector('#top-section-tour');
if (heroEl) {
  heroView();
}

let tourMenuEl = document.querySelector('#top-section-tour');
if (tourMenuEl) {
  tourMenuView();
}

let overviewEl = document.querySelector('#overview-section');
if (overviewEl) {
  overviewView();
}

let availabilityEl = document.querySelector('#app-availability');
if (availabilityEl) {
  availabilityList();
}

let itineraryEl = document.querySelector('#itinerary-section-view');
if (itineraryEl) {
  itineraryView();
}

const shipsEl = document.querySelector('#ships-section');
if (shipsEl) {
  shipsView();
}

const includedEl = document.querySelector('#included-section');
if (includedEl) {
  includedView();
}

const itineraryModalEl = document.querySelectorAll('.itinerary-modal');
if (itineraryModalEl) {
  itineraryModal();
}

const tabsEl = document.querySelectorAll('.tabs');
if (tabsEl) {
  materializeFunctions();
}

const secundaryMenuEl = document.querySelector('#secundary-menu');
if (secundaryMenuEl) {
  secundaryMenu();
}

const contentModulesEl = document.querySelector('#content-modules');
if (contentModulesEl) {
  contentModules();
}

const contentModulesCardEl = document.querySelector('#content-modules-card');
if (contentModulesCardEl) {
  contentModulesInfoCard();
}

const brandLogoImageEl = document.querySelector('.brand-logo-image');
if (brandLogoImageEl) {
  brandLogoImage();
}

// HERO Sections
let heroGenericViewEl = document.querySelector('#hero-generic-section');
if (heroGenericViewEl) {
  heroGenericView();
}

let heroBrandViewEl = document.querySelector('#hero-brand-section');
if (heroBrandViewEl) {
  heroBrandView();
}

let bannerViewEl = document.querySelector('#generic-banner');
if (bannerViewEl) {
  BrandView();
}

let heroCarouselViewEl = document.querySelector('#hero-carousel-section');
if (heroCarouselViewEl) {
  heroCarouselView();
}

let heroEmbeddedSearchViewEl = document.querySelector('#hero-embeddedSearch');
if (heroEmbeddedSearchViewEl) {
  heroEmbeddedSearchView();
}

let featuredDestinationsViewEl = document.querySelector('#featured-destinations');
if (featuredDestinationsViewEl) {
  featureDestinations();
}

let featuredParentSectionViewEl = document.querySelector('#featured-parent-section');
if (featuredParentSectionViewEl) {
  featuredParentSectionView();
}

let featuredIntroSectionViewEl = document.querySelector('#featured-intro-section');
if (featuredIntroSectionViewEl) {
  featuredIntroSectionView();
}

// HOMEPAGE
let featuredBrandsViewEl = document.querySelector('#featured-brands');
if (featuredBrandsViewEl) {
  featureBrands();
}

const tourPage = document.querySelector('#tourPage');
if (tourPage) {
  getTourPage();
}

let subNavEl = document.querySelector('.subnav-control');
if (subNavEl) {
  $(document).ready(function () {
    subnavControl();
  });
}

document.addEventListener('DOMContentLoaded', function () {
  mainNavHelper();
  mobileMenu();
  autoCompleteSearch();
  stickyNav();
  stickyMap();
  backToTop();
  scrollTo();
  fixIEImages();
  featureExpand();
  brandPods();
  smoothScroll();
  featureSection();
  accordion();

  const bookingWidgetBoxEl = document.querySelector('.widget-container__box');
  if (bookingWidgetBoxEl) {
    bookingWidget();
  }
});
