<template>
  <section class="featured feature-section">
    <div
      v-for="feature in featuredsection"
      :id="feature.featureId"
      class="feature-section__item"
      :style="[
        $mq == 'desktop'
          ? {
            backgroundImage: 'url(\'' + feature.backgroundImageUrl + '\')'
          }
          : ''
      ]"
      :arial-label="feature.backgroundImageAltText"
    >
      <div
        v-if="$mq === 'laptop' || $mq === 'tablet' || $mq === 'mobile'"
        class="feature-section__item-bg"
      >
        <img
          v-if="feature.backgroundImageUrl"
          class="item-bg"
          :src="feature.backgroundImageUrl"
          :alt="feature.backgroundImageAltText"
        >
      </div>

      <div
        class="feature-section__content"
        :class="
          feature.featureType === 'brand' &&
            (feature.featureId.toLowerCase() === 'luxury-gold' ||
              feature.featureId.toLowerCase() === 'trafalgar')
            ? 'bg-' + feature.featureId.toLowerCase()
            : 'bg-feature'
        "
      >
        <div class="feature-section__content-inner">
          <div class="feature-smallImage">
            <img
              v-if="feature.imageUrl && feature.featureType !== 'destination'"
              class="feature-image"
              :class="feature.featureType === 'brand' ? 'feature-logo-image' : ''"
              :src="feature.imageUrl"
              :alt="feature.imageAltText"
            >
            <h3
              v-if="
                feature.featureType === 'destination' ||
                  feature.featureType === 'homeTravelStyleFeature'
              "
              class="feature-title"
            >
              {{ feature.featureTitleName }}
            </h3>
          </div>

          <div
            v-if="feature.descriptionText"
            class="feature-text"
            v-html="feature.descriptionText"
          />
          <footer
            v-if="feature.linkUrl"
            class="feature-section__footer"
          >
            <div
              class="feature-section__footer-item"
              :class="feature.featureType !== 'homeTravelStyleFeature' ? 'center' : ''"
            >
              <a
                class="button is-primary cta"
                :href="feature.linkUrl"
                :aria-label="feature.linkName"
                target="_blank"
              >{{ feature.linkName }}</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['featuredsection'],
  data() {
    return {};
  }
};
</script>
