<template>
  <div
    v-if="content.validationActiveCheckbox"
    id="validateModal"
    class="modal modal-validate"
    :class="{ 'is-active': active }"
  >
    <div class="modal-background" />
    <div class="modal-card">
      <div class="modal-form">
        <div class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="content.validationHeaderText"
          />
        </div>
        <section class="modal-card-body">
          <p v-text="content.validationMessage" />
          <p v-html="content.validationInstructionText" />
          <p class="countdown-text">
            {{ content.validationReDirectText }}&nbsp;<strong>{{ countdownTimerValue }}</strong>&nbsp;seconds.
          </p>
        </section>
        <div class="modal-card-foot">
          <button
            type="button"
            class="button is-primary width-auto"
            @click="redirect(content.validationRedirectUrl)"
            v-text="content.validationButtonText"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['content'],
  data() {
    return {
      active: false,
      countdownTimerValue: this.content.validationCountdownSeconds
    };
  },
  created: function () {
    if (this.content.validationActiveCheckbox) {
      let self = this;
      let memberNumberValid = sessionStorage.getItem('memberValid');
      let currentUrl = window.location.href;
      let url = new URL(currentUrl);
      let memberIdParam = url.searchParams.get('memberId');

      if (!memberNumberValid) {
        function showInvalidModal() {
          self.active = true;

          setInterval(function () {
            if (self.countdownTimerValue > 0) {
              self.countdownTimerValue = self.countdownTimerValue - 1;
            } else {
              self.redirect(self.content.validationRedirectUrl);
            }
          }, 1000);
        }

        if (memberIdParam == null) {
          showInvalidModal();
        } else {
          const regexNumbers = /\d{4}/;
          const regexTestLetters = memberIdParam.startsWith('LGW');
          let regexTestNumbers = regexNumbers.test(memberIdParam);
          let paramsAreValid = regexTestLetters && regexTestNumbers;

          if (!paramsAreValid) {
            showInvalidModal();
          } else {
            sessionStorage.setItem('memberValid', true);
            sessionStorage.setItem('memberId', memberIdParam);
          }
        }
      }
    }
  },
  methods: {
    redirect(url) {
      window.location.href = url;
    }
  }
};
</script>
