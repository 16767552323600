<template>
  <div class="brands__content columns">
    <div
      v-for="(card, index) in modulescard"
      :id="card.travelStyleId"
      :key="index"
      data-type="navigation"
      data-name="travel-pod"
      class="pod-card"
    >
      <div class="pod-card-content">
        <div
          v-if="card.imageUrl"
          class="pod-card-header"
        >
          <a
            v-if="card.linkUrl"
            :href="card.linkUrl"
            class="pod-card-content__link"
            :alt="card.title"
            :aria-label="card.title"
            :style="{
              backgroundImage: 'url(\'' + card.imageUrl + '\')',
              backgroundPosition: '' + card.leftFocalPoint + ' ' + card.topFocalPoint + ''
            }"
          />
          <div
            v-if="!card.linkUrl"
            class="pod-card-content__link"
            :style="{
              backgroundImage: 'url(\'' + card.imageUrl + '\')',
              backgroundPosition: '' + card.leftFocalPoint + ' ' + card.topFocalPoint + ''
            }"
          />
        </div>
        <div class="pod-card-content-body">
          <div
            class="pod-card-content-body__header"
            :class="card.iconUrl ? 'has-icon' : ''"
          >
            <img
              v-if="card.iconUrl"
              class="header-icon"
              :src="card.iconUrl"
              :alt="card.iconAltTag ? card.iconAltTag : ''"
            >
            <a
              v-if="card.linkUrl"
              :href="card.linkUrl"
              class="header-title"
            >
              <h3>{{ card.title }}</h3>
            </a>
          </div>
          <div
            v-if="card.text"
            class="pod-card-content-body-text"
          >
            {{ card.text }}
          </div>
        </div>
        <div class="pod-card-footer">
          <div
            v-if="card.linkUrl"
            class="pod-card-footer-inner"
          >
            <a
              v-if="card.linkName !== ''"
              class="pod-card-footer__link"
              :href="card.linkUrl"
              target="_blank"
              :aria-label="card.title"
            >
              {{ card.linkName }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['modulescard'],
  data() {
    return {};
  },
  created() {}
};
</script>
