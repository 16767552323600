<template>
  <section class="search-destination-container">
    <div class="search-destination-wrapper">
      <div class="search-destination-row destination-wrapper">
        <div
          id="destination-mobile"
          class="search-destination-regions destination-mobile"
        >
          <div class="search-filter-title destination__trigger">
            <p
              class="dropdown-subheading is-dektop"
              v-text="filterTripsContent.destination.regionDisplayText"
            />
            <p
              class="dropdown-subheading is-mobile"
              v-text="
                selectedRegionMobile === ''
                  ? filterTripsContent.destination.regionDisplayText
                  : selectedRegionMobile
              "
            />
            <div class="arrow" />
          </div>
          <ul class="region-list custom-options">
            <li
              v-for="(continent, continentIndex) in filterTripsContent.destination.continents"
              class="region-item"
            >
              <a
                :key="'continent' + continentIndex"
                class="button highlight search-destination-item custom-option"
                :data-tabindex="continentIndex"
                :class="[
                  continentIndex === currentTab && selectedRegion === currentDestination
                    ? 'selected is-active'
                    : '',
                  ,
                  !selectedRegion && !selectedDestinations.length ? 'not-selected' : ''
                ]"
                :data-target="'#' + continent.continentName"
                :data-searchterm="continent.continentApiName"
                href="#"
                @click.prevent="regionClick"
              >
                {{ continent.continentName }}
              </a>
            </li>
          </ul>
        </div>

        <div class="search-destination-countries">
          <div
            v-for="(continent, continentIndex) in filterTripsContent.destination.continents"
            :key="continentIndex"
            class="search-destination-country-container"
          >
            <div
              v-if="continentIndex === currentTab"
              class="search-destination-region-wrapper"
            >
              <div class="search-filter-title">
                <p
                  class="dropdown-subheading"
                  v-text="countriestext"
                />
                <a
                  class="search-destination-item search-all-header"
                  :class="{
                    'is-active selected': continentIndex === currentTab && regionSearchSelected
                  }"
                  :data-continent="true"
                  :data-display-value="continent.continentName"
                  :data-region-id="continentIndex"
                  :value="continent.continentApiName"
                  :data-searchterm="continent.continentApiName"
                  href="#"
                  @click="regionSearch"
                >
                  Search all in
                  {{ continent.continentName }}
                </a>
              </div>
              <div
                v-if="continent.countries"
                class="country-holder"
              >
                <ul class="search-destination-column">
                  <li
                    v-for="(country, index) in continent.countries"
                    :key="'country' + index"
                    class="country-item"
                    :class="[
                      continent.hasTopCountries && country.isTopCountry
                        ? 'is-top-country'
                        : !continent.hasTopCountries
                          ? ''
                          : 'no-top-country',
                      showAll ? 'showCountry' : ''
                    ]"
                  >
                    <button
                      :data-display-value="country.countryApiName"
                      :data-region-id="continentIndex"
                      :value="country.countryApiName"
                      class="country-item-btn search-destination-item"
                      @click="selectedCountries($event, continent, continentIndex)"
                    >
                      {{ country.countryName }}
                    </button>
                  </li>
                </ul>
                <div v-if="continent.hasTopCountries">
                  <button
                    v-if="!showAll"
                    class="show-countries"
                    @click="showAllCountries"
                  >
                    Show All Countries
                  </button>
                  <button
                    v-if="showAll"
                    class="show-countries"
                    @click="showTopCountries"
                  >
                    Show Top Countries
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import * as searchConstants from '../../search-constants.js';
import eventBus from '../../vue-functions/event-bus';

export default {
  props: [
    'filterTripsContent',
    'destinations',
    'continent',
    'searchtype',
    'countriestext',
    'locked',
    'filterActions',
    'currentRegionSelected'
  ],
  data() {
    return {
      currentTab: 0,
      selectedDestinations: [],
      buttonActiveClass: 'is-active',
      selectedRegion: false,
      selectedRegionMobile: false,
      regionSearchSelected: false,
      destinationValues: null,
      showAll: false,
      currentDestination: ''
      //currentDestination = this.filterTripsContent.destination.continents[0].continentApiName;
      //selectedRegion = this.filterTripsContent.destination.continents[0].continentApiName;
    };
  },
  watch: {
    destinations: function (newVal) {
      // have set a watch on this so that our destinations data is updated
      // when a search is fired from the vue search component ( via formFieldSearch function )
      this.destinationValues = newVal;
    },
    continent: function (newVal) {
      // watch it
      // have set a watch on this so that our continent data is updated
      // when a search is fired from the vue search component ( via formFieldSearch function )
      this.selectedRegion = newVal;
    }
  },
  mounted() {
    // this function exists to set the filter states if destinations // regions are passed to the component via props
    if (this.searchtype === searchConstants.REGIONSSEARCHTERM) {
      this.selectedRegion = this.continent;
      this.regionSearchSelected = true;
    } else if (this.destinations && this.destinations.length > 0) {
      this.destinationValues = this.destinations;
      this.updateDestinations();
    }
    if (!this.locked) {
      this.setButtonActiveStates();
    }
    let defaultContinentOnLoad = this.filterTripsContent.destination.continents[0].continentApiName;
    this.currentDestination = defaultContinentOnLoad;
    this.selectedRegion = defaultContinentOnLoad;
  },
  created() {
    eventBus.$on('destinations-set-active-states', () => {
      this.setButtonActiveStates();
    });
    eventBus.$on('destinations-clear-values', () => {
      this.clearValues();
    });
    eventBus.$on('set-values-from-search-input', (searchType) => {
      this.setValuesFromSearchInput(searchType);
    });
  },
  methods: {
    updateDestinations() {
      let splitDestinationValues = this.destinationValues?.split(',');
      for (let i = 0; i < splitDestinationValues.length; i++) {
        let decodedDestination = decodeURIComponent(splitDestinationValues[i]);
        // push each value in the array into the local selectedDestinations array
        this.selectedDestinations.push(decodedDestination);
      }
    },
    regionClick(event) {
      let btn = event.currentTarget;
      this.switchTabs(event);
      this.clearValues();
      this.$parent.$emit('destinations-region-click', this.selectedDestinations);
      this.regionSearchSelected = !this.regionSearchSelected;
      this.selectedRegion = btn.dataset.searchterm;
      this.selectedRegionMobile = btn.dataset.target.substring(1);
      btn.classList.add(this.buttonActiveClass);

      this.$parent.$emit('destinations-region-search', this.selectedRegion);
    },
    regionSearch(event) {
      let btn = event.currentTarget;
      this.regionSearchSelected = !this.regionSearchSelected;

      if (this.regionSearchSelected) {
        // get search term from button
        this.selectedRegion = btn.dataset.searchterm;
        // clear off country buttons if we are searching for whole region
        this.clearValues();
      } else {
        this.selectedRegion = false;
      }
      this.selectedRegion = btn.dataset.searchterm;
      this.currentDestination = btn.dataset.searchterm;
      this.$parent.$emit('destinations-region-search', this.selectedRegion);
    },
    searchAllRegion(event) {
      let btn = event.currentTarget;
      this.switchTabs(event);
      this.clearValues();

      this.regionSearchSelected = !this.regionSearchSelected;
      this.selectedRegion = btn.dataset.searchterm;

      this.$parent.$emit('destinations-region-search', this.selectedRegion);
    },
    clearRegion() {
      this.selectedRegion = false;
      this.regionSearchSelected = false;
      this.$parent.$emit('clear-destinations', this.selectedRegion);
    },
    switchTabs(event) {
      // method for tab visibility - this takes the data-tabindex from the clicked tab
      // and sets the tab index, which in turn drives thew v-show on the tabs
      let tabClicked = event.currentTarget;
      let tabIndex = parseInt(tabClicked.dataset.tabindex, 10);
      this.currentTab = tabIndex;
      this.clearRegion();
    },
    selectedCountries(event, continent, tab) {
      let buttonValue = event.target.value;
      let countriesArray = this.selectedDestinations;
      if (countriesArray.includes(buttonValue)) {
        let spliceKey = countriesArray.indexOf(buttonValue);
        countriesArray.splice(spliceKey, 1);
        event.target.classList.remove(this.buttonActiveClass);
      } else {
        countriesArray.push(buttonValue);
        event.target.classList.add(this.buttonActiveClass);
        this.clearRegion();
      }
      this.selectedRegion = continent.continentName;
      this.currentDestination = continent.continentApiName;
      this.$parent.$emit('destinations-filter', countriesArray);
    },
    clearValues() {
      this.selectedDestinations.length = 0;
      let buttons = this.getButtonDomRefs();

      // loop through buttons and remove the active class
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove(this.buttonActiveClass);
      }
      if (!this.selectedRegion || this.selectedRegion !== '') {
        this.clearRegion;
      }
      this.showAll = false;
    },
    setValuesFromSearchInput(searchType) {
      // this function is called from formFieldSearch if a destination or continent is picked from the search field in nav bar on parent search page
      // run on nextTick to allow destinations / continents  to be updated via the watches at top of this component
      if (searchType === 'region') {
        this.regionSearchSelected = true;
        this.$nextTick(() => {
          this.setButtonActiveStates();
        });
      } else {
        this.$nextTick(() => {
          this.updateDestinations();
          this.regionSearchSelected = false;
          this.setButtonActiveStates();
        });
      }
    },
    getButtonDomRefs: function () {
      let region = document.querySelector('.search-destination-wrapper');
      if (region) {
        return region.querySelectorAll('.search-destination-item');
      }
      return false;
    },
    setButtonActiveStates() {
      let buttons = this.getButtonDomRefs();
      if (buttons) {
        let regionId = 0;
        for (let i = 0; i < buttons.length; i++) {
          let currentBtn = buttons[i];
          // setting up these 2 values to check against due to the disparity between destinations in the v4 API & the CMS
          // basically the search service send an API name & a display name - the display name is for customers, and the api name
          // is what the search service needs
          // so to check against both for future robustness, I check against both.
          let btnValue = currentBtn.value;
          let btnDisplayValue = currentBtn.dataset.displayValue;
          if (
            this.selectedDestinations.includes(btnValue) ||
            this.selectedDestinations.includes(btnDisplayValue)
          ) {
            currentBtn.classList.add(this.buttonActiveClass);
            regionId = parseInt(currentBtn.dataset.regionId, 10);
          }

          // need to tab switch if a region is chosen, so compare value,and get the region id based on value
          // the region buttons have dynamic -is-active class is regionSearchSelected = true & their regionID is the current Index
          if (
            (this.regionSearchSelected && this.selectedRegion === btnValue) ||
            (this.regionSearchSelected && this.selectedRegion === btnDisplayValue)
          ) {
            regionId = parseInt(currentBtn.dataset.regionId, 10);
          }
        }
        this.currentTab = regionId;
      }
    },
    showAllCountries() {
      this.showAll = true;
    },
    showTopCountries() {
      this.showAll = false;
    }
  }
};
</script>
