<template>
  <div
    id="geqmodalraq"
    class="modal modal-geq"
    :class="{ 'is-active': active }"
    @keydown.esc.prevent="closeForm()"
  >
    <div
      class="modal-background"
      @click.prevent="closeForm()"
    />
    <div class="modal-card">
      <div
        v-show="!submitted"
        class="modal-form"
      >
        <div class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="labels.modalTitle"
          />
          <a
            class="modal-card-close bg-transparent"
            href="#"
            aria-label="close"
            @click.prevent="closeForm()"
          >
            <i class="fa fa-times fa-lg" />
          </a>
        </div>
        <form @submit.prevent="validateBeforeSubmit">
          <section class="modal-card-body">
            <p
              class="label"
              v-text="modal.brandName"
            />
            <h2
              v-if="modal.tourName"
              v-text="modal.tourName"
            />

            <div class="columns">
              <div
                v-if="modal.brandName === 'Contiki'"
                class="column"
              >
                <div class="field">
                  <input
                    id="ageConsent"
                    v-model="quoteRequest.ageConsent"
                    v-validate="'required'"
                    name="ageConsent"
                    :data-vv-as="labels.ageConsent"
                    class="is-checkradio"
                    type="checkbox"
                  >
                  <label
                    for="ageConsent"
                    class="label"
                    v-html="labels.ageConsent"
                  />
                  <span
                    v-if="errors.has('ageConsent')"
                    class="field-validation-error"
                  >{{
                    errors.first('ageConsent')
                  }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="quoteRequest.iscmstour || quoteRequest.isuniworldtour || quoteRequest.isubytour"
            >
              <div
                v-if="modal.departureDates"
                class="columns"
              >
                <div class="column">
                  <div class="field">
                    <label class="label">{{ labels.departureDate }}</label>
                    <div class="control">
                      <select
                        id="departureDate"
                        v-model="quoteRequest.departureDate"
                        class="input input-subtle"
                        aria-label="labeldepartureDate"
                      >
                        <option value="">
                          {{ labels.notSure }}
                        </option>
                        <option
                          v-for="option in modal.departureDates"
                          :key="option.value"
                          :value="option.value"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                      <span class="select-dropdown-icon">
                        <i
                          aria-hidden="true"
                          class="fas fa-chevron-down"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="modal.departureDates"
                class="columns"
              >
                <div class="column">
                  <div class="field">
                    <label class="label">{{ labels.departureDate }}</label>
                    <div class="control">
                      <select
                        id="departureId"
                        v-model="quoteRequest.departureId"
                        class="input input-subtle"
                        aria-label="labeldepartureDate"
                        @change="departureDateChange()"
                      >
                        <option value="">
                          {{ labels.notSure }}
                        </option>
                        <option
                          v-for="option in modal.departureDates"
                          :key="option.departureId"
                          :value="option.departureId"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                      <span class="select-dropdown-icon">
                        <i
                          aria-hidden="true"
                          class="fas fa-chevron-down"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <input
                id="departureDate"
                v-model="quoteRequest.departureDate"
                name="departureDate"
                type="hidden"
                v-text="quoteRequest.departureDate"
              >
            </div>
            <div
              v-if="labels.showMemberField"
              class="columns"
            >
              <div class="column">
                <div class="field">
                  <label
                    class="label"
                    :class="{
                      'required-label': labels.memberFieldMandatory
                    }"
                  >{{ labels.labelMemberNo }}</label>
                  <input
                    id="membershipno"
                    v-model="quoteRequest.membershipNo"
                    v-validate="{
                      required: labels.memberFieldMandatory,
                      numeric: !labels.alphanumericCriteria,
                      min: labels.memberIdCriteria ? labels.memberIdCriteria : '1',
                      max: labels.memberIdCriteria ? labels.memberIdCriteria : '16',
                      regex: labels.alphanumericCriteria
                        ? /^(?=.*\d)(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
                        : /^([0-9]+)$/
                    }"
                    class="input input-subtle"
                    :type="labels.alphanumericCriteria || memberNoValidator ? 'text' : 'number'"
                    :class="{
                      disabled: isLoggedIn || memberNoValidator
                    }"
                    :disabled="isLoggedIn || memberNoValidator"
                    name="membershipno"
                    aria-label="labelmembershipno"
                    :data-vv-as="labels.labelMemberNo"
                    :placeholder="labels.memberFieldWaterMark"
                  >
                  <span
                    v-if="errors.has('membershipno')"
                    class="field-validation-error"
                  >{{
                    errors.first('membershipno')
                  }}</span>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelFirstname }}</label>
                  <div class="control">
                    <input
                      id="firstname"
                      v-model="quoteRequest.firstName"
                      v-validate="'required|max:40'"
                      :data-vv-as="labels.labelFirstname"
                      aria-label="labelfirstname"
                      name="firstname"
                      class="input input-subtle"
                      type="text"
                    >
                    <span
                      v-if="errors.has('firstname')"
                      class="field-validation-error"
                    >{{
                      errors.first('firstname')
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelLastname }}</label>
                  <div class="control">
                    <input
                      id="lastname"
                      v-model="quoteRequest.lastName"
                      v-validate="'required|max:40'"
                      :data-vv-as="labels.labelLastname"
                      aria-label="labellastname"
                      name="lastname"
                      class="input input-subtle"
                      type="text"
                    >
                    <span
                      v-if="errors.has('lastname')"
                      class="field-validation-error"
                    >{{
                      errors.first('lastname')
                    }}</span>
                  </div>
                </div>
              </div>
              <input
                id="toemailaddress"
                name="toemailaddress"
                type="hidden"
                v-text="quoteRequest.toemailaddress"
              >
              <input
                id="ccemailaddress"
                name="ccemailaddress"
                type="hidden"
                v-text="quoteRequest.ccemailaddress"
              >
              <input
                id="aaecategory"
                name="aaecategory"
                type="hidden"
                v-text="quoteRequest.aaecategory"
              >
              <input
                id="iscmstour"
                name="iscmstour"
                type="hidden"
                v-text="quoteRequest.iscmstour"
              >
              <input
                id="isuniworldtour"
                name="isuniworldtour"
                type="hidden"
                v-text="quoteRequest.isuniworldtour"
              >
              <input
                id="isubytour"
                name="isubytour"
                type="hidden"
                v-text="quoteRequest.isubytour"
              >
              <input
                id="urlquoterequest"
                name="urlquoterequest"
                type="hidden"
                v-text="quoteRequest.urlquoterequest"
              >
              <input
                id="tourid"
                name="tourid"
                type="hidden"
                v-text="quoteRequest.tourid"
              >
              <input
                id="touroptionid"
                name="touroptionid"
                type="hidden"
                v-text="quoteRequest.touroptionid"
              >
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelEmail }}</label>
                  <div class="control">
                    <input
                      id="Email"
                      v-model="quoteRequest.email"
                      v-validate="'required|email'"
                      :data-vv-as="labels.labelEmail"
                      aria-label="labelEmail"
                      name="Email"
                      class="input input-subtle"
                      type="email"
                    >
                    <span
                      v-if="errors.has('Email')"
                      class="field-validation-error"
                    >{{
                      errors.first('Email')
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label
                    class="label"
                    v-text="labels.labelPhone"
                  />
                  <div class="control">
                    <input
                      id="phone"
                      v-model="quoteRequest.phone"
                      v-validate="'numeric|max: 22'"
                      name="phone"
                      aria-label="labelphone"
                      :placeholder="labels.placeholderOptional"
                      class="input input-subtle"
                    >
                    <span
                      v-if="errors.has('phone')"
                      class="field-validation-error"
                    >{{
                      errors.first('phone')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="labels.showZipCode"
              class="columns"
            >
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelZipOrPostalCode }}</label>
                  <div class="control">
                    <input
                      id="zipCode"
                      v-model="quoteRequest.zipCode"
                      v-validate="zipCodeValidationRules"
                      name="zipCode"
                      aria-label="labelZipCode"
                      class="input input-subtle"
                    >
                    <input
                      id="showZipCode"
                      name="showZipCode"
                      type="hidden"
                      v-text="labels.showZipCode"
                    >
                    <span
                      v-if="errors.has('zipCode')"
                      class="field-validation-error"
                    >{{
                      errors.first('zipCode')
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="column" />
            </div>
            <div class="columns margin-bottom">
              <div class="column">
                <div class="field">
                  <label
                    class="label"
                    v-text="labels.labelComments"
                  />
                  <div class="control">
                    <textarea
                      v-model="quoteRequest.comments"
                      type="text"
                      aria-label="labels.placeholderComments"
                      :placeholder="labels.placeholderComments"
                      class="input input-subtle"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showNewsLetterCheckbox"
              class="columns"
            >
              <div class="column">
                <div class="field">
                  <input
                    id="marketing"
                    v-model="quoteRequest.requestNewsLetter"
                    name="marketing"
                    class="is-checkradio"
                    type="checkbox"
                  >
                  <label
                    for="marketing"
                    class="label marketing"
                    v-html="labels.newsletterMessage"
                  />
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <input
                    id="agreeTerms"
                    v-model="quoteRequest.agreeTerms"
                    v-validate="'required'"
                    name="agreeTerms"
                    :data-vv-as="labels.agreeTerms"
                    class="is-checkradio"
                    type="checkbox"
                  >
                  <label
                    for="agreeTerms"
                    class="label"
                    v-html="labels.labelAgreeTerms"
                  />
                  <span
                    v-if="errors.has('agreeTerms')"
                    class="field-validation-error"
                  >{{
                    errors.first('agreeTerms')
                  }}</span>
                </div>
              </div>
            </div>
          </section>
          <div class="modal-card-foot">
            <button
              type="submit"
              :disabled="!quoteRequest.agreeTerms"
              class="button is-secondary show-confirm cta"
              :class="{ 'is-loading': isLoading }"
              v-text="!isLoading ? labels.submitButton : ''"
            />
          </div>
        </form>
      </div>
      <div
        v-show="submitted"
        id="model-confirm-raq"
        class="modal-confirm"
      >
        <div class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="labels.modalTitle"
          />
          <a
            class="modal-card-close bg-transparent"
            href="#"
            aria-label="close"
            @click.prevent="closeForm()"
          >
            <i class="fa fa-times fa-lg" />
          </a>
        </div>
        <div class="modal-card-image">
          <img
            :src="labels.submittedBackgroundImage"
            :alt="
              labels.submittedBackgroundImageAltText
                ? labels.submittedBackgroundImageAltText
                : 'modal-background-image'
            "
            class="is-background"
          >
          <div class="card-content">
            <h2
              v-show="!submittedError"
              class="modal-confirm__success-header"
              v-text="labels.submittedTitle"
            />
            <h2
              v-show="submittedError"
              v-text="labels.submittedErrorTitle"
            />
          </div>
        </div>
        <div class="modal-card-body">
          <p
            v-show="!submittedError"
            v-text="labels.submittedText"
          />
          <p
            v-show="submittedError"
            v-text="labels.submittedErrorText"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
import axios from 'axios';
import { closeModal } from '../../modals/closeModal';

const dict = {
  custom: {
    agreeTerms: {
      required: ''
    },
    ageConsent: {
      required: ''
    }
  }
};
export default {
  props: {
    labels: Object,
    api: String,
    toemailaddress: String,
    ccemailaddress: String,
    aaecategory: String,
    membershipno: String,
    isLoggedIn: { type: Boolean, default: false },
    emaillead: Boolean,
    islistingpage: Boolean,
    iscmstour: Boolean,
    urlquoterequest: String,
    tourid: String,
    touroptionid: String,
    isuniworldtour: Boolean,
    isubytour: Boolean
  },
  data() {
    return {
      isLoading: false,
      active: false,
      modal: false,
      submitted: false,
      submittedError: false,
      quoteRequest: {
        departureDate: '',
        membershipNo: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zipCode: '',
        showZipCode: false,
        comments: '',
        tourName: '',
        brandValue: '',
        agreeTerms: false,
        requestNewsLetter: false,
        toemailaddress: '',
        ccemailaddress: '',
        aaecategory: '',
        iscmstour: false,
        urlquoterequest: '',
        tourid: '',
        touroptionid: '',
        startdate: '',
        departureId: '',
        isuniworldtour: false,
        isubytour: false
      },
      memberNoValidator: false,
      targetButton: '',
      selectedButton: '',
      isEmailLead: false,
      membershipNumberandLastNameInvalid: false
    };
  },
  computed: {
    zipCodeValidationRules() {
      return this.labels.showZipCode ? 'required|alpha_dash|max: 9' : 'alpha_dash|max: 9';
    },
    showNewsLetterCheckbox() {
      if (this.islistingpage && this.emaillead) {
        return false;
      } else {
        return !this.isEmailLead && this.labels.newsletterMessage;
      }
    }
  },
  watch: {
    active() {
      if (this.active) {
        setTimeout(() => {
          const modal = document.querySelector('#geqmodalraq .input');
          modal.focus();
        }, 200);
      } else {
        this.selectedButton.focus();
      }
    },
    submitted() {
      if (this.submitted) {
        this.dataLayerSubmit();
      }
    },
    membershipNumberandLastNameInvalid() {
      if (this.labels.showMemberField) {
        if (this.membershipNumberandLastNameInvalid) {
          this.dataLayerError();
        }
      }
    }
  },
  created: function () {
    dict.custom.agreeTerms.required = this.labels['termsRequiredMessage'];
    dict.custom.ageConsent.required = this.labels['ageConsentTermsMessage'];

    this.quoteRequest.toemailaddress = this.toemailaddress;
    this.quoteRequest.ccemailaddress = this.ccemailaddress;
    this.quoteRequest.iscmstour = this.iscmstour;
    this.quoteRequest.aaecategory = this.aaecategory;
    this.quoteRequest.urlquoterequest = this.urlquoterequest;
    this.quoteRequest.tourid = this.tourid;
    this.quoteRequest.touroptionid = this.touroptionid;
    this.quoteRequest.isuniworldtour = this.isuniworldtour;
    this.quoteRequest.isubytour = this.isubytour;
    this.quoteRequest.showZipCode = this.labels.showZipCode;
    this.modal = { brand: { name: '' } };
    this.$validator.localize('en', dict);
    EventBus.$on('TOGGLE_RAQ_MODAL', (row, tourSelectedDate, tourDepartureId) => {
      this.modal = row;
      if (typeof row.brand.brandValue !== 'undefined') {
        this.modal.brandName = row.brand.name;
        this.quoteRequest.brandValue = row.brand.brandValue;
      } else {
        this.quoteRequest.brandValue = row.brand;
      }
      this.quoteRequest.departureDate = tourSelectedDate;
      this.quoteRequest.departureId = tourDepartureId;
      this.quoteRequest.tourName = row.tourName;
      this.quoteRequest.aaecategory = row.aaeCategory;
      this.quoteRequest.iscmstour = row.isCmsTour;
      this.quoteRequest.isuniworldtour = row.isUniworldTour;
      this.quoteRequest.isubytour = row.isUbyTour;
      this.quoteRequest.urlquoterequest = row.url;
      this.quoteRequest.tourid = row.tourId;
      this.quoteRequest.touroptionid = row.tourOptionId;
      document.documentElement.classList.add('is-clipped');
      this.show();
    });
    if (!this.active) {
      EventBus.$on('update-raq-target', (target) => {
        this.targetButton = target;
        this.selectedButton = target;
      });
      EventBus.$on('update-is-email-lead', (emailLead) => {
        this.isEmailLead = emailLead;
      });
    }
  },
  mounted() {
    this.closeModal(this.closeForm);
    this.quoteRequest.requestNewsLetter = this.labels.newsletterDefaultOptIn;
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.handleSubmit();
        }
      });
    },
    show() {
      this.active = true;

      let memberNumberValid = sessionStorage.getItem('memberValid');
      if (memberNumberValid) {
        this.memberNoValidator = true;
        this.quoteRequest.membershipNo = sessionStorage.getItem('memberId');
      } else if (this.quoteRequest.membershipNo === '') {
        this.quoteRequest.membershipNo = this.membershipno;
      }

      this.toggleBodyClass();
    },
    toggleBodyClass() {
      let bodyEl = document.querySelector('body');
      bodyEl.classList.toggle('is-modal-active');
    },
    submittedForm() {
      this.submitted = true;
    },
    closeForm() {
      this.submitted = false;
      this.active = false;
      document.documentElement.classList.remove('is-clipped');
      this.toggleBodyClass();
      document.querySelector('.modal-card-body').parentElement.classList.remove('scroll-more');
    },
    handleSubmit() {
      if (!this.quoteRequest.agreeTerms) {
        return;
      }
      var self = this;
      self.isLoading = true;
      axios
        .post(this.api, this.quoteRequest, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function (response) {
          self.isLoading = false;
          if (response.data.Status === 0) {
            self.submittedError = false;
            self.submittedForm();
          } else {
            switch (response.data.Status) {
              case 2:
                switch (response.data.ParameterName) {
                  case 'MembershipNo':
                    self.$validator.errors.add({
                      field: 'membershipno',
                      msg: response.data.Message
                    });
                    self.membershipNumberandLastNameInvalid = true;
                    break;
                  case 'LastName':
                    self.$validator.errors.add({
                      field: 'lastname',
                      msg: response.data.Message
                    });
                    break;
                }
                break;
              case 1:
                self.membershipNumberandLastNameInvalid = true;
              case 3: //Expired.
                self.$validator.errors.add({
                  field: 'membershipno',
                  msg: response.data.Message
                });
                self.membershipNumberandLastNameInvalid = true;
                break;
            }
          }
        })
        .catch(function () {
          self.isLoading = false;
          self.submittedError = true;
          self.submittedForm();
        });
    },
    closeModal,
    dataLayerError() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'RAQ Modal Membership Number / Last Name Error'
      });
    },
    dataLayerSubmit() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'RAQ Modal Submitted' });
    },
    departureDateChange() {
      var departureDateHiddenField = document.getElementById('departureDate');
      var departureDateDropdown = document.getElementById('departureId');
      var departureDateSelected =
        departureDateDropdown.options[departureDateDropdown.selectedIndex].text;
      departureDateHiddenField.value = departureDateSelected;
      this.quoteRequest.departureDate = departureDateSelected;
    }
  }
};
</script>
