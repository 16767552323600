import Vue from 'vue';
import appEmbeddedSearch from '../vue-components/apps/embedded-search.vue';

function embeddedSearch() {
  new Vue({
    el: '#app-embeddedSearch',
    components: {
      appEmbeddedSearch
    },
    data: function () {
      return {
        appId: 'app-embeddedSearch'
      };
    }
  });
}
export default embeddedSearch;
