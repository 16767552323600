<template>
  <div
    :class="{ 'is-active': filterVisibility.showDestinationFilter }"
    class="dropdown"
  >
    <div class="dropdown-trigger">
      <a
        href="#"
        class="button is-filter"
        aria-haspopup="true"
        aria-controls="dropdown-destination"
        :disabled="settings.loading"
        :class="{
          'is-filter-active': settings.filtersText.destinationsText
        }"
        @click.prevent="$emit('destination-filter-actions', '#dropdown-destination')"
      >
        <span
          v-if="embeddedsearchcontent && !settings.filtersText.destinationsText"
          v-text="embeddedsearchcontent.destinationPlaceHolderText"
        />
        <span v-if="!settings.filtersText.destinationsText && !embeddedsearchcontent">{{
          settings.filterTripsContent.destination.destinationMenuText
        }}</span>
        <span v-if="settings.filtersText.destinationsText">{{
          settings.filtersText.destinationsText
        }}</span>
        <span
          v-if="!settings.filtersLocked"
          class="icon is-small"
        >
          <i
            class="fas fa-chevron-down"
            aria-hidden="true"
          />
          <i
            class="fas fa-chevron-up"
            aria-hidden="true"
          />
        </span>
      </a>
    </div>
    <div
      id="dropdown-destination"
      class="dropdown-menu dropdown-destination"
      role="presentation"
    >
      <div class="dropdown-header">
        <div class="level dropdown-header-wrap">
          <div class="is-mobile dropdown-header-data">
            <h2 class="dropdown-subheading navbar-item_title">
              {{ settings.filterTripsContent.destination.regionDisplayText }}
            </h2>
          </div>
          <div class="dropdown-header-close">
            <button
              class="dropdown-close"
              @click.prevent="setFilterVisibility()"
            >
              <span class="is-sr-only">Close Menu</span>
              <span
                class="dropdown-close__icon delete"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
      <keep-alive>
        <search-destination-filters
          ref="destinations"
          :countriestext="settings.filterTripsContent.destination.mustSeeCountriesDisplayText"
          :locked="settings.filtersLocked"
          :searchtype="settings.currentSearchPrefix"
          :destinations="settings.currentDestinations"
          :continent="settings.currentContinent"
          :filter-trips-content="settings.filterTripsContent"
          :filter-actions="settings.filterActions"
          :current-region-selected="settings.currentContinent"
        />
      </keep-alive>
      <div class="search-footer">
        <div class="buttonholder search-clear-apply">
          <button
            class="btn-clear"
            href="#"
            @click="clearDestinations"
          >
            Clear Selection
          </button>
          <button
            class="btn-apply"
            @click.prevent="handleApplyClick(settings.filterActions.destinationActionClicked)"
          >
            Apply Selection
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchDestinationFilters from '../../vue-components/search/search-destination-filters.vue';
import handleApplyClick from '../../search-functions/global-filter-functions/handle-apply-click';

export default {
  components: {
    searchDestinationFilters
  },
  props: {
    settings: {
      type: Object
    },
    filterVisibility: {
      type: Object
    },
    embeddedsearchcontent: {
      type: Object
    }
  },
  data() {
    return {
      regionActive: ''
    };
  },
  methods: {
    handleApplyClick,
    setFilterVisibility: function (target) {
      this.$emit('set-filter-visibility', target);
    },
    filterSearch: function () {
      this.$emit('filter-search');
    },
    clearDestinations: function () {
      this.$emit('reset-destination-filters');
    }
  }
};
</script>
